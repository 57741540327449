<template>
    <Modal :inner-class="'lpAlertBox'" v-if="alert" :close="close">
        <div class="text-center px-6">
            <p>{{ alert.message }}</p>
        </div>
    </Modal>
</template>
<script lang="ts">
import Modal from '@/components/ui/atoms/Modal.vue';
export default {
    mounted(){
        //Listen to anywhere click not containing class lpAlertBox
        document.addEventListener('click', this.onOutsideClick); 
    },
    beforeUnmount(){
        document.removeEventListener('click', this.onOutsideClick);
    },
    components:{
        Modal
    },
    watch: {
        alert(){
            if(this.alert?.autoClose){
                setTimeout(this.close, this.alert.autoClose);
            }
        }
    },
    computed:{
        alert(): { message: string, autoClose: number}|null{
            return this.$store.state.alerts[0] || null;
        }
    },
    methods:{
        close(){
            this.$store.commit('removeAlert');
        },
        onOutsideClick(e: MouseEvent){
            if(!e.target.closest('.lpAlertBox')){
                this.close();
            }
        }
    }
}
</script>