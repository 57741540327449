<template>
    <Wrapper class="align-center justify-center" :variant="'light-silver-screen'">
        <div class="flex justify-center items-center">
                <Spinner v-if="isLoading" :size="'[32px]'" />
                <Text v-if="!error" :variant="'h1'">{{ title }}</Text>
                <Text class="text-red" v-else :variant="'h1'"> X - {{ error }}</Text>
        </div>
    </Wrapper>
</template>
<script lang="ts">
import Spinner from '@/components/ui/atoms/Spinner.vue';
import Text from '@/components/ui/atoms/Text.vue';
import Wrapper from '@/components/ui/atoms/Wrapper.vue';
import { OAuthAppsApi } from '@/utils/apis/OAuthAppsApi';

export default {
  components: {
    Wrapper,
    Spinner,
    Text
  },
  data() {
    return {
      isLoading: true,
      error: '',
      title: 'Authorizing application'
    };
  },
  props: ['clientId'],
  mounted() {
    document.title = 'Authorize Application';
    this.authorize();
  },
  methods: {
    async authorize(){
        const authorized = await OAuthAppsApi.authorize(this.clientId);
        this.isLoading = false;
        if(authorized.error){
            this.error = authorized.message || "There was an error authorizing the application";
        }
        else{
            const data = authorized.data;
            this.title = `Redirecting to ${data.name}....`;
            window.location.href = data.redirectUrl;
        }
    }
  }

};
</script>