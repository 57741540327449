<template>
    <div class="flex flex-col items-center max-w-4xl py-4 md:py-[2.5rem] gap-10 px-4">
        <img src="/icons/hi-fi.svg" />
        <div>
            <Text :variant="'h1'" :align="'center'">Great Job!</Text>
            <Text :variant="'h2'" :color="'gray'" :align="'center'" :thickness="'normal'">
                Your hotel has been submitted successfully. Our team will review your submission and<br>
                notify you via email when your information is validated. For any questions, contact us at
                <br><a class="text-lpBlue underline" href="mailto: travel@crewfare.com">travel@crewfare.com</a>
            </Text>
        </div>
        <div class="flex flex-col gap-y-4 md:flex-row justify-between gap-x-4">
            <Button @click="$emit('submitted', false)" :variant="'secondary'" :theme="'light'">
                + Sign Up Another Hotel
            </Button>
            <Button @click="handleHomeRedirect" :variant="'secondary'" :theme="'light'">
                <HomeIcon />
                <span>Go to Homepage</span>
            </Button>
        </div>
    </div>
</template>
<script lang="ts">
import Button from '../../atoms/Button.vue';
import HomeIcon from '../../atoms/icons/HomeIcon.vue';
import Text from '../../atoms/Text.vue';

export default {
    components:{
        Text,
        Button,
        HomeIcon
    },
    methods: {
        handleHomeRedirect(){
            window.location.href = 'https://crewfare.com';
        }
    }
}
</script>