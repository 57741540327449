<template>
  <div class="flex flex-col gap-[8px]" :class="variant == 'dark' ? 'lp-dark': 'lp-light'">
    <Text v-if="title" :variant="'label'" :color="variant == 'dark' ? 'white' : 'thickGray'">{{ title }} <span class="text-crewfareBlue" v-if="required"> *</span></Text>
    <div class="flex gap-4 items-center">
      <vue-tel-input 
        @blur="emitBlur"
        v-if="isPhoneInput" 
        :class="inputClasses"
        v-model="phoneNumber"
        @on-input="handlePhoneInput"
        :inputOptions="{
          placeholder: placeholder,
        }"
        :dropdownOptions="{
          showDialCodeInSelection: true,
          showFlags: true
        }"
        :validCharactersOnly="true"
        @validate="handlePhoneValidation"
      >
      </vue-tel-input>
      <GMapAutocomplete
        @blur="emitBlur"
        v-else-if="isLocationSearch"
        class=""
        :class="inputClasses"
        :placeholder="placeholder"
        @place_changed="(place: any) => locationFunction?.(place)"
        :value="inputValue"
      />
      <input
        @blur="emitBlur"
        type="text"
        :placeholder="placeholder"
        :class="inputClasses"
        :disabled="isDisabled || isLocked"
        v-model="inputValue"
        @input="handleTextInput"
        v-else
        :readonly="readonly"
      />
      <div
        v-if="iconUrl"
        class="w-6 h-6 bg-contain bg-no-repeat cursor-pointer"
        :style="{ backgroundImage: `url(${iconUrl})` }"
        @click="() => onClickIcon?.()"
      ></div>
    </div>
    <p v-if="blurred && isError" class="text-crewfareRed text-xs">{{ errorMessage }}</p>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Text from './Text.vue';

const variants = ['dark', 'light'] as const;

export default {
  components:{
    Text,
  },
  props: {
    isTitleText: {
      type: Boolean,
      default: false,
    },
    title: String,
    isBoldTitle: Boolean,
    placeholder: String,
    isError: Boolean,
    isLocked: Boolean,
    isDisabled: Boolean,
    errorMessage: String,
    iconUrl: String,
    onClickIcon: Function,
    modelValue: String,
    isLocationSearch: Boolean,
    locationFunction: Function,
    readonly: Boolean,
    variant: {
      type: String as PropType<typeof variants[number]>,
      default: 'dark',
    },
    required: Boolean,
    isPhoneInput: Boolean,
  },
  data() {
    return {
      inputValue: this.modelValue,
      blurred: false,
      phoneNumber: '',
      classes: {
        default: ' border-transparent',
        error: '  !border-crewfareRed',
        locked: ' hover:border-transparent border-transparent',
        disabled:
          ' text-crewfareGrey cursor-not-allowed border-crewfareGreyLighter hover:border-crewfareGreyLighter',
      } as { [key: string]: string },
      phone: {
        countryCode: '',
        number: '',
        isValid: false,
        countryCallingCode: '',
      },
    };
  },
  computed: {
    actionableClass() {
      switch (true) {
        case this.isLocked:
        return this.classes.locked;
        case this.blurred && this.isError:
        return this.classes.error;
        case this.isDisabled:
        return this.classes.disabled;
        default:
        return this.classes.default;
      }
    },
    inputClasses(){
      const classMap = {
        'dark': 'box-border min-w-[255px] !rounded-[8px] text-sm hover:!border-crewfarePurple appearance-none !border-2 focus:outline-none font-normal text-white focus:ring-0 focus:outline-none focus:border-transparent w-full bg-crewfareGrey ',
        'light': 'box-border min-w-[255px] !rounded-[8px] text-sm hover:!border-crewfarePurple appearance-none !border-2 focus:outline-none font-normal text-black focus:ring-0 focus:outline-none focus:border-transparent w-full bg-lpWhite ',
      }
      return {
        [classMap[this.variant]]: true,
        [this.actionableClass]: true,
        'light': this.variant === 'light',
        'dark': this.variant === 'dark',
        'lp-tel-input py-1 px-1 border-none': this.isPhoneInput,
        'px-4 py-3': !this.isPhoneInput,
      };
    },
    labelClasses(){
      const classMap = {
        'dark': 'text-white text-base',
        'light': 'text-black text-base',
      }

      return classMap[this.variant] + `${this.isBoldTitle ? ' font-bold' : ''} ${!this.isBoldTitle ? ' font-medium' : ''}` + this.actionableClass;
    }
  },
  mounted(){
    this.phoneNumber = this.modelValue || '';
  },
  methods: {
    handleTextInput(event: any){
      let value = (event.target as HTMLInputElement).value
      if(this.isTitleText){
        value = value.replace(/[^a-zA-Z0-9 .,]/g, "")
        value = value.replace(/(,+)/g,',')
        value = value.replace(/\.+/g,'.')
        value = value.replace(/,+[ ]*[,\.]+/g,',')  
        value = value.replace(/\.+[ ]*[,\.]+/g,'.')  
      }
      this.inputValue = value;
      this.$emit('update:modelValue', value)
    },
    emitBlur(){
      this.blurred = true;
      this.$emit('blur');
    },
    handlePhoneInput(number: string, phoneObject: any){
      this.handlePhoneValidation(phoneObject);
    },
    handlePhoneValidation(phoneObject: any){
      this.phone.isValid = phoneObject.valid;
      this.phone.number = phoneObject.nationalNumber;
      this.phone.countryCode = phoneObject.countryCode;
      this.phone.countryCallingCode = phoneObject.countryCallingCode
      this.$emit('update:modelValue', phoneObject.number); 
      this.$emit('change', this.phone);
    }
  },
  emits: ['update:modelValue', 'change', 'blur'],
};
</script>
