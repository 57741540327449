import { BaseApi, getToken } from './apiFactory';

class HotelSignupsApi extends BaseApi {
  approve = async (id: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}/approve`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  deny = async (id: string, reason: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}/deny`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { reason },
    });
  };
}

export const hotelSignupsApi = new HotelSignupsApi({ name: 'hotel-signups' });
