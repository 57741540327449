<template>
    <div @click="copy" class="relative text-base cursor-pointer hover:text-sky-200 flex items-center">
        <span :class="underline? 'underline': ''" >{{ content }}</span>
        <span class="relative flex items-end pl-2">
            <span class="material-symbols-rounded text-[24px]">content_copy</span>
            <span class="text-xs absolute left-8" v-if="copied">Copied!</span>
        </span>
    </div>
</template>
<script lang="ts">
export default{
    props: {
        content: {
            type: String,
            required: true
        },
        underline: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            copied: false
        }
    },
    methods: {
        copy(){
            console.log('copying');
            navigator.clipboard.writeText(this.content).then(() => {
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 200);
            });
        }
    }
}
</script>