import { BaseApi, getToken } from './apiFactory';

class ContractsApi extends BaseApi {
  async sign(id: string, body: { signature: string; name: string; title: string }) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}/sign`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: body,
    });
  }
  async requestChange(
    id: string,
    data: {
      contract_content: string;
    },
  ) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}/request-change`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  }

  async acceptChanges(
    id: string,
    data: {
      contract_content: string;
    },
  ) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${id}/accept-changes`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  }
}

export const contractsApi = new ContractsApi({ name: 'contract' });
