<template>
    <div class="relative flex flex-col" :class="classes">
        <slot></slot>
    </div>

</template>
<script lang="ts">
import { PropType } from 'vue';

// Define the variants as a constant array
const variants = ['light-silver-screen', 'dark-bg-component'] as const;
export default{
    props: {
        variant: {
            type: String as PropType<typeof variants[number]>,
            default: 'dark-bg-component'
        },
  },
    computed: {
        classes() {
        return {
            'light-silver-screen': 'bg-lpSilverGray min-h-screen min-w-screen',
            'dark-bg-component': 'bg-crewfareBlack w-full h-fit'
        }[this.variant];
        },
    },
  
}
</script>