<template>
    <Loading class="self-center mt-24" v-if="isLoading" />
    <div class="w-full flex flex-col gap-4 p-4 text-white" v-else>
      <div class="text-xl text-left font-bold">{{ editorTitle }}</div>
      <div class="w-[300px] flex flex-col gap-y-3">
        <Input :isError="!name" v-model="name" :placeholder="'Type App Name'" :title="'App Name'" />
        <Input :isError="!isUrlValid" v-model="redirectUrl" :placeholder="'Redirect Url'" :title="'Redirect Url'" />
        <p class="text-xs">Note: URL must start with http:// or https://</p>
        <Select v-model="selectedStatus" :options="statusOptions" :title="'Status'" />
        <Button class="w-fit" :isDisabled="!checkRequiredFields" @click="save">Save</Button>
      </div>
    </div>
  </template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button.vue';
import Input from '@/components/ui/atoms/Input.vue';
import Loading from '@/components/ui/atoms/Loading.vue';
import Select from '@/components/ui/atoms/Select.vue';
import { OAuthAppsApi } from '@/utils/apis/OAuthAppsApi';
import { isValidUrl } from '@crewfare/server-shared';

export default{
    async mounted(){
        if(this.appId){
            const response = await OAuthAppsApi.get(this.appId);
            if(response.error){
                alert(response.message || 'An error occurred');
                this.$router.push({ path: '/settings/oauth-apps' });
            }
            else{
                const app = response.data;
                this.name = app.name;
                this.redirectUrl = app.redirectUrl;
                this.selectedStatus = app.isActive ? { name: 'Active', value: true } : { name: 'Inactive', value: false };
            }
        }
        this.isLoading = false;

    },
    components: {
        Input,
        Button,
        Select,
        Loading
    },
    props: {
        appId: {
            type: String,
        }
    },
    computed: {
        editorTitle(){
            return this.appId ? 'Edit OAuth App' : 'Add OAuth App';
        },
        checkRequiredFields(){
            return this.name && isValidUrl(this.redirectUrl);
        },
        isUrlValid(){
            return isValidUrl(this.redirectUrl);
        }
    },
    data(){
        return {
            isLoading: true,
            name: '',
            statusOptions: [
                { name: 'Active', value: true },
                { name: 'Inactive', value: false },
            ],
            selectedStatus: { name: 'Inactive', value: false },
            createdBy: '',
            created_at: new Date(),
            updated_at: new Date(),
            redirectUrl: '',
            isActive: false,
        }
    },
    methods: {
        handleActiveChange(){
            console.log("Active status changed");
        },
        async save(){
            this.isLoading = true;
            let response;
            if(this.appId){
                response = await OAuthAppsApi.update(this.appId, {
                    name: this.name,
                    redirectUrl: this.redirectUrl,
                    isActive: this.selectedStatus.value
                });
            }else{
                response = await OAuthAppsApi.create({
                    name: this.name,
                    redirectUrl: this.redirectUrl,
                    isActive: this.selectedStatus.value,
                });
            }
            if(response.error){
                alert(response.message || 'An error occurred');
                this.isLoading = false;
            }
            else{
                this.isLoading = false;
                const clientSecret = response.data.secretRaw;
                clientSecret && prompt(`Please copy and save your client secret, you won't be able to see it again:`, clientSecret);
                this.$router.push({ path: '/settings/oauth-apps' });
            }
        }
    }
}
</script>