function parseGooglePlace(place: any) {
  const address = place.formatted_address;
  const latitude = place.geometry.location.lat().toString();
  const longitude = place.geometry.location.lng().toString();
  const state =
    place.address_components.find((component: { types: string | any[] }) =>
      component.types.includes('administrative_area_level_1'),
    )?.long_name || '';
  const city =
    place.address_components.find((component: { types: string | any[] }) => component.types.includes('locality'))
      ?.long_name || state;
  const country =
    place.address_components.find((component: { types: string | any[] }) => component.types.includes('country'))
      ?.long_name || '';
  const zipCode =
    place.address_components.find((component: { types: string | any[] }) => component.types.includes('postal_code'))
      ?.long_name || '';
  return {
    address,
    latitude,
    longitude,
    state,
    city,
    country,
    zipCode,
  };
}

export { parseGooglePlace };
