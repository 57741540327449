<template>
    <div class="absolute left-0, bottom-0 w-full h-full z-[0]">
        <img :src="src" class="w-full h-full object-contain" />
    </div>
</template>
<script lang="ts">
export default {
    props: {
        src: {
            type: String,
            default: ''
        }
    }
}
</script>