
<template>
    <div class="flex flex-col text-white p-6 gap-4">
      <div class="flex flex-col gap-2 bg-crewfareGreyDark p-4 rounded-md">
        <div class="text-base font-bold">OAuth Applications</div>
        <!-- OAuth Apps List -->
            <div class="w-full flex flex-col gap items-center">
                <div class="flex flex-col gap-2 items-center w-full text-left" v-if="!isLoading">
                    <div class="grid grid-cols-5 gap-y-4 items-center w-full font-bold text-crewfareGreen">
                    <div>Name</div>
                    <div>Client ID</div>
                    <div>Last Updated</div>
                    <div>Status</div>
                    <div>Actions</div>
                    </div>
                    <Divider class="mb-2" />
                    <div class="grid grid-cols-5 gap-y-4 items-center w-full" v-for="app in apps">
                    <div class="text-base">{{ app.name }}</div>
                    <div class="text-base">{{ app.id }}</div>
                    <div class="text-base">{{ formatDate(app.updatedAt) }}</div>
                    <div class="text-base">{{ app.isActive ? 'Active' : 'Inactive' }}</div>
                    <div class="flex gap-4">
                        <img src="/icons/i-pencil.svg" @click="() => editOAuthApp(app.id)" class="cursor-pointer w-4" />
                    </div>
                    </div>
                    <Button class="w-fit self-end mt-4" @click="addOAuthApp">Add New App</Button>
                </div>
                <Loading v-else />
            </div>
        <!-- OAuth Apps List ends here-->
      </div>
    </div>
</template>
  
  <script>
  import Button from '@/components/ui/atoms/Button.vue';
  import Divider from '@/components/ui/atoms/Divider.vue';
  import Loading from '@/components/ui/atoms/Loading.vue';
  import { OAuthAppsApi } from '@/utils/apis/OAuthAppsApi';
  import { getDateObjFromFirebaseDate } from '@/utils/dateUtils';
  
  export default {
    data() {
      return {
        isLoading: true,
        apps: [],
      };
    },
    components: {
      Divider,
      Button,
      Loading,
    },
    methods: {
      formatDate(date) {
        return getDateObjFromFirebaseDate(date).toDateString();
      },
      async getOAuthApps() {
        this.apps = await OAuthAppsApi.list().then(res => res.data);
        this.isLoading = false;
        return;
      },
      addOAuthApp() {
        this.$router.push({ path: '/settings/oauth-apps/edit' });
      },
      editOAuthApp(id) {
        this.$router.push({ path: '/settings/oauth-apps/edit', query: { appId: id } });
      },
    },
    mounted() {
      document.title = 'Launchpad - Manage OAuth Apps';
      this.getOAuthApps();
    },
  };
  </script>
  