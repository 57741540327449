<template>
    <img src="/logo.svg" :class="`h-[${size}]`" />
</template>
<script lang="ts">
export default{
    props: {
        size: {
            type: String,
            default: '32px'
        }
    }
}
</script>