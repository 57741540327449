import { useReCaptcha } from 'vue-recaptcha-v3';
export const recaptchSetup = () => {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() as any;
  console.log('Seturp called', executeRecaptcha, recaptchaLoaded);

  const recaptchaV3 = async () => {
    // (optional) Wait until recaptcha has been loaded.
    await recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    const token = await executeRecaptcha('hotel_signup');

    return token;
  };

  return {
    recaptchaV3,
  };
};
