<template>
    <Wrapper :variant="'light-silver-screen'">
        <Wrapper :variant="'dark-bg-component'">
            <HotelSignupHeader :isSubmitted="isSubmitted" />
        </Wrapper> 
        <div v-if="!isSubmitted" class="relative flex flex-1 items-center">
            <AbsoluteBg src="/hotel-signup-bg.svg" />
            <div class="flex flex-col md:flex-row w-full">
                <div class="flex justify-center md:w-3/4 z-[1]">
                    <HotelSignupForm class="px-4" @submitted="onSubmit" />
                </div>
                <div class="md:w-1/4 z-[1]">
                    <KeyFeatures />
                </div>
            </div>
        </div>
        <div v-else class="flex flex-1 justify-center">
            <HotelSignupSubmitted @submitted="onSubmit" />
        </div>
    </Wrapper>
</template>
<script lang="ts">
import Wrapper from '@/components/ui/atoms/Wrapper.vue';
import HotelSignupForm from '@/components/ui/molecules/HotelSignup/HotelSignupForm.vue';
import KeyFeatures from '@/components/ui/molecules/HotelSignup/KeyFeatures.vue';
import HotelSignupHeader from '@/components/ui/molecules/HotelSignup/HotelSignupHeader.vue';
import AbsoluteBg from '@/components/ui/atoms/AbsoluteBg.vue';
import HotelSignupSubmitted from '@/components/ui/molecules/HotelSignup/HotelSignupSubmitted.vue';


export default{
    components: {
        Wrapper,
        HotelSignupHeader,
        HotelSignupForm,
        KeyFeatures,
        AbsoluteBg,
        HotelSignupSubmitted
    },
    data(){
        return {
            isSubmitted: false
        }
    },
    mounted(){
        document.title = 'Launchpad - Hotel Signup';
    },
    methods: {
        onSubmit(isSubmitted: boolean){
            this.isSubmitted = isSubmitted;
        }
    }
}
</script>