<template>
    <div class="text-white px-6 py-3 bg-crewfareGreyDark">
        <div class="flex flex-col gap-6">
            <div class="flex items-center gap-3">
                <img src="/icons/i-hotel.svg" class="h-7 w-7" />
                <div class="text-2xl font-bold">Pending Hotel Signups</div>
            </div>
            <AdminTable :loading="loading" :columns="columns" :rows="rows" :actions="actions as any"/>
            <Pagination
                :actual="currentPage"
                :max="perPage"
                :total="total"
                @togglePage="togglePage"
            />
        </div>
    </div>
</template>
<script lang="ts">
import Pagination from '@/components/default/Pagination.vue';
import AdminTable from '@/components/ui/molecules/AdminTable/AdminTable.vue';
import { hotelSignupsApi } from '@/utils/apis/hotelSignupsApi';
import { getMomentFromFirebaseDate } from '@/utils/dateUtils';
import { debounce } from '@/utils/formatter';
import { lpAlert, lpPrompt } from '@/utils/lpNativeEvents';
import { AccountTypes, DuplicateStatus, HotelSignupStatus } from '@crewfare/server-shared';

export default {
    async mounted(){
        if(![AccountTypes.NSO, AccountTypes.Super_Admin].includes(this.account.type.toLowerCase())){
            this.$router.push('/');
        }
        else{
            this.debounceList()
        }
        
    },
    computed: {
        account(){
            return this.$store.state.account;
        }
    },
    components:{
        AdminTable,
        Pagination
    },
    methods: {
        togglePage(page: any){
            this.currentPage = page;
            this.debounceList();
        },
        debounceList: debounce(async function(this: any){
            this.controller.abort();
            this.controller = new AbortController();
            const currentSignal = this.controller.signal;
            this.getData(currentSignal);
        }, 500),
        async getData(signal: any, loadingState = true){
            this.loading = loadingState;
            try{
                const { data, error, message } = await hotelSignupsApi.list({
                    perPage: this.perPage,
                    page: this.currentPage + 1,
                    statuses: [ HotelSignupStatus.PENDING]
                }, signal);
                if(signal != this.controller.signal) return;
                if(error){
                    alert(message || 'Error fetching signup data');
                    this.loading = false;
                    return;
                }
                this.rows = data.signups;
                this.total = data.count;
                this.$store.commit("setHotelSignupCount", this.total)
                this.loading = false;
            }catch(e: any){
                alert(e.message||'Error fetching data');
            }
        },
        async handleSignupApprove(row: any){
            console.log('Approve', row);
            let confirm = window.confirm(`Are you sure you want to approve this signup for ${row.hotelName}?`);
            if(!confirm) return;
            const approvalRes = await hotelSignupsApi.approve(row.id)
            if(approvalRes.error){
                alert(approvalRes.message)
                return;
            }
            await this.getData(this.controller.signal, false)
            lpAlert(`Hotel ${row.hotelName} successfully added.`)
        },
        async handleSignupDeny(row: any){
            const reason = await lpPrompt(
                            "You are about to deny this hotel addition.",
                            "Please provide a denial reason below which will be communicated with the person who added the hotel."
                        )
            if(!reason) return;
            const denialRes = await hotelSignupsApi.deny(row.id, reason)
            if( denialRes.error ){
                alert(denialRes.message)
                return;
            }
            await this.getData(this.controller.signal, false)
        },
    },
    data(){
        return {
            controller: new AbortController(),
            perPage: 20,
            currentPage: 0,
            total: 0,
            loading: false,
            actions: [
                {
                    key: 'approve',
                    label: 'Approve',
                    variant: "primary",
                    callback: async (row: any)=>{
                        return this.handleSignupApprove(row);
                    }
                },
                {
                    key: 'deny',
                    label: 'Deny',
                    variant: "danger",
                    callback: async (row: any)=>{
                        return this.handleSignupDeny(row);
                    }
                }
            ],
            columns: [
                {
                    key: 'hotelName',
                    name: 'Hotel Name',
                    errorText: ( row : any ) => row.duplicacy !== DuplicateStatus.NEW ? row.duplicacy : '',
                },
                {
                    key: 'address',
                    name: 'Address',
                },
                {
                    key: 'chain',
                    name: 'Chain',
                },
                {
                    key: 'contactName',
                    name: 'Added By',
                },
                {
                    key: 'dateAdded',
                    name: 'Submitted On',
                    transform: (row: any) => {
                        return getMomentFromFirebaseDate(row.createdAt).format('ddd MMM DD, YYYY');
                    }
                },
            ],
            rows: []
        }
    }
}
</script>