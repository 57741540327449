<template>
    <div v-if="truncate" class="group">
        <component :data-contentWidth="contentWidth" :data-textwidth="textWidth" class="peer" :is="variant" :class="classes">
            <slot></slot>
        </component>
        <p v-if="isTruncated" class="relative hidden group-hover:block">
            <span class="absolute top-0 left-0 bg-crewfareGreyLight p-1 whitespace-nowrap"><slot></slot></span>
        </p>
    </div>
    <component v-else class="peer" :is="variant" :class="classes">
        <slot></slot>
    </component>
</template>
<script lang="ts">
import { PropType } from 'vue';

const variants = [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'label', 'p' ] as const;
const colors = ['black', 'dark', "thickGray", 'gray', 'lightGray', 'purple', 'lightPurple', 'white'] as const;
const thickness = ['thin', 'normal', 'medium', 'semiBold', 'bold'] as const;
const alignments = ['left', 'center', 'right', 'justify'] as const;
export default {
    mounted(){
        this.calculateTextWidth();
    },
    created() {
    window.addEventListener("resize", this.calculateTextWidth);
    },
    destroyed() {
    window.removeEventListener("resize", this.calculateTextWidth);
    },
    data() {
        return {
            isTruncated: false,
            textWidth: 0,
            contentWidth: 0
        }
    },
    props: {
        variant :{
            type: String as PropType<typeof variants[number]>,
            default: 'h1',
        },
        color: {
            type: String as PropType<typeof colors[number]>,
            default: 'dark',
        },
        thickness: {
            type: String as PropType<typeof thickness[number]>,
            default: 'medium',
        },
        align: {
            type: String as PropType<typeof alignments[number]>,
            default: 'left',
        },
        truncate: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }

    },

    computed: {
        classes() {
            const classMap = {
                'h1': 'text-4xl leading-[3rem]',
                'h2': 'text-base leading-[1.5rem]',
                'h3': 'text-sm',
                'h4': 'text-xl',
                'h5': 'text-lg',
                'h6': 'text-base',
                'label': 'text-sm',
                'p': 'text-xs',
                'black': 'text-lpBlack',
                'dark': 'text-lpDark',
                'gray': 'text-lpGray',
                'lightGray': 'text-lpLightGray',
                'thickGray': 'text-lpThickGray',
                'purple': 'text-lpPurple',
                'lightPurple': 'text-lpLightPurple',
                'white': 'text-lpWhite',
                'thin': 'font-thin',
                'normal': 'font-normal',
                'medium': 'font-medium',
                'semiBold': 'font-semibold',
                'bold': 'font-bold',
                'left': 'text-left',
                'center': 'text-center',
                'right': 'text-right',
                'justify': 'text-justify',
                'truncate': ' whitespace-nowrap text-ellipsis hover:text-clip overflow-hidden'
            }
            return `${classMap[this.variant]} ${classMap[this.color]} ${classMap[this.thickness]} ${classMap[this.align]} ${this.truncate ? classMap['truncate'] : ''}`;
        }
    },
    methods: {
        calculateTextWidth(){
            if(!this.truncate) return;
            const contentText = this.$el.textContent;
            const contentWidth = this.$el.offsetWidth;
            const fontSize = window.getComputedStyle(this.$el).fontSize;
            const fontFamily = window.getComputedStyle(this.$el).fontFamily;
            //Compute font width using canvas method
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            if(!context) return;
            //Set the font size and font family
            context.font = `${fontSize} ${fontFamily}`;
            const textWidth = context.measureText(contentText).width;
            if(textWidth > contentWidth){
                this.isTruncated = true;
            }
            this.textWidth = textWidth;
            this.contentWidth = contentWidth;
        }
    },
}
</script>