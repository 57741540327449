import { isSourceUser } from '../accounts/userTypesUtil';

export const hasCounteredLast = (proposal, account) => {
  const roomRateListHistoric = proposal.roomListHistoric || [];
  const currentOwner = isSourceUser(account) ? 'source' : 'hotel';
  //If there's no entry it's source's turn
  if (roomRateListHistoric.length == 0) {
    return currentOwner === 'source';
  }
  const lastEntry = [...roomRateListHistoric].reverse()[0];
  const rfpLastSignedBy =
    !lastEntry.account || isSourceUser(lastEntry.account) ? 'source' : 'hotel';
  return currentOwner === rfpLastSignedBy;
};
