<template>
    <div ref="infoTip" class="relative tooltip-wrapper flex items-center justify-center">
        <span :class="`material-symbols-rounded text-[${size}] cursor-pointer`">
            Help
        </span>
        <div ref="info" class="fixed lp-tip bg-gray-800 border-2 border-gray-700 rounded-md p-2 z-[1000] w-[300px]" :style="`top: ${top}px; left: ${left}px;`">
            <slot></slot>
        </div>
    </div>
</template>
<script lang="ts">
export default {
    mounted(){
        this.calculatePosition();
        window.addEventListener('resize', this.calculatePosition);
        window.addEventListener('scroll', this.calculatePosition);
    },
    beforeUnmount(){
        window.removeEventListener('resize', this.calculatePosition);
        window.removeEventListener('scroll', this.calculatePosition);
    },
    data(){
        return {
            top: 0,
            left: 0
        }
    },
    props: {
        size: {
            type: String,
            default: '28px'
        }
    },
    methods:{
        calculatePosition(){
            const { top, left } = (this.$refs.infoTip as HTMLElement).getBoundingClientRect();
            const infoBarWidth = (this.$refs.info as HTMLElement).getBoundingClientRect().width;
            const infoBarHeight = (this.$refs.info as HTMLElement).getBoundingClientRect().height;
            const spaceRemainingRight = window.innerWidth - left;
            if(left > spaceRemainingRight){
                this.left = Math.floor(left - infoBarWidth);
            }else{
                this.left = left;
            }
            if(top - infoBarHeight > 0){
                this.top = Math.floor(top - infoBarHeight);
            }else{
                this.top = top;
            }
        }
    }
}
</script>