<template>
  <div
    v-if="rfp"
    class="bg-gray-800 w-full flex flex-col stage-height text-white"
  >
    <div
      v-if="rfp_hotels.length > 0"
      class="flex flex-col rounded pb-4 relative"
    >
      <div
        class="flex p-4 items-center justify-between sticky top-0 bg-gray-800 z-40"
      >
        <div class="flex gap-2 items-center">
          <div class="text-crewfareGreen">
            <strong class="rounded-full bg-black px-3 py-1">{{
              hotelsCount
            }}</strong>
            Hotels
          </div>
        </div>
        <div class="flex items-center gap-4">
          <span class="text-xs">{{ selected.length }} selected</span>
          <button @click="toggleAll" class="border px-4 py-1 rounded text-sm">
            {{ isAllSelected ? "Unselect All" : "Select All" }}
          </button>
          <div class="text-white">
            <select
              @change="filterStatus"
              v-model="status"
              class="w-full bg-gray-700 px-4 rounded py-2"
            >
              <option selected value="All">All status</option>
              <option v-for="(item, index) in listStatus" :key="index">
                {{ item }}
              </option>
            </select>
          </div>
          <input
            type="search"
            v-model="search"
            class="bg-gray-700 rounded px-4"
            placeholder="Search..."
            @keyup="doSearch"
          />
        </div>
      </div>
      <div class="divide-y divide-y-gray-900">
        <div v-for="listRFP in listRfps" :key="listRFP.name">
          <div
            @click="toggleActive(listRFP.name)"
            class="hover:bg-gray-700 p-4 cursor-pointer flex justify-between items-center"
            :class="activeLists.includes(listRFP.name) && 'bg-gray-900'"
          >
            <span>
              {{ listRFP.name }} ({{
                listRFP.lists.total > 1
                  ? `${listRFP.lists.total} hotels`
                  : "1 hotel"
              }})
            </span>
            <span
              v-if="activeLists.includes(listRFP.name)"
              class="text-crewfareGreen material-symbols-rounded"
            >
              expand_less
            </span>
            <span v-else class="text-crewfareGreen material-symbols-rounded">
              expand_more
            </span>
          </div>
          <div
            v-if="activeLists.includes(listRFP.name)"
            v-for="(by_status, indexStatus) in listRFP.lists.by_status"
            :key="indexStatus"
            class="flex flex-col"
          >
            <div v-if="by_status.list.length > 0" class="flex flex-col">
              <div
                class="py-2 pb-0 px-4 bg-gray-700 capitalize text-gray-300 text-sm flex justify-between items-center"
              >
                <span>
                  {{ by_status.status }} - {{ by_status.list.length }} hotels
                </span>
                <button
                  v-if="by_status.status === 'new'"
                  class="px-2 py-1 border hover:bg-gray-200 hover:text-gray-900 text-gray-200 rounded h-[34px]"
                  @click="
                    sendRFP({
                      selected: by_status.list.filter(rfp=>this.selectedRFPIds.includes(rfp.id)),
                    })
                  "
                >
                  <span v-if="loadingSend" class="flex gap-2 items-center">
                    <span
                      class="material-symbols-rounded animate-spin flex items-center"
                    >
                      autorenew
                    </span>
                    Sending RFP
                  </span>
                  <span v-else> Send RFP </span>
                </button>
                <span v-if="hasReminder.includes(by_status.status)" class="flex items-center gap-x-5">
                  <Toggle v-if="listRFP.name == 'Sent RFPS'" @change="handleAutoRemindChange" :checked="rfp?.autoRemind" :title="'Automatic Reminder'">
                    <div class="flex flex-col">
                      <span>
                          Auto remind 2 days after the RFP was sent.
                      </span>
                      <span>
                          Auto remind 5 days after the RFP was sent.
                      </span>
                      <span>
                          Auto remind 7 days after the RFP was sent.
                      </span>
                  </div>
                  </Toggle>
                  <button
                    class="px-2 py-1 border hover:bg-gray-200 hover:text-gray-900 text-gray-200 rounded h-[34px]"
                    @click="
                      reminderRFP({
                        selected: by_status.list.filter(rfp=>this.selectedRFPIds.includes(rfp.id)),
                      })
                    "
                  >
                    <span v-if="loadingReminder" class="flex gap-2 items-center">
                      <span
                        class="material-symbols-rounded animate-spin flex items-center"
                      >
                        autorenew
                      </span>
                      Sending reminder
                    </span>
                    <span v-else> Send reminder </span>
                  </button>
                </span>
              </div>
              <table class="w-full text-white px-4 justify-between py-2">
                <tr
                  class="border-b text-sm font-regular border-gray-700 bg-gray-700"
                >
                  <th class="text-left p-4 py-2 items-start">
                    <div class="flex items-center gap-2">
                      <button
                        v-if="listRFP.isSelectable || by_status.isSelectable"
                        class="flex items-center"
                        @click="selectSection(listRFP.id, by_status)"
                      >
                        <span
                          v-if="!(listRFP.allSelected || by_status.allSelected)"
                          class="material-symbols-rounded"
                          >check_box_outline_blank</span
                        >
                        <span
                          v-else
                          class="material-symbols-rounded text-crewfareGreen"
                          >check_box</span
                        >
                      </button>
                      Name
                    </div>
                  </th>
                  <th class="text-left px-4">Distance</th>
                  <th class="text-left px-4">Proposal</th>
                  <th v-if="listRFP.name == 'Sent RFPS'" class="text-left px-4">Email Status</th>
                  <th class="text-left px-4">Last Update At</th>
                </tr>
                <tbody class="divide-y divide-gray-700">
                  <ListItemHotel
                    v-for="rfp in by_status.list"
                    :rfp="rfp"
                    :key="rfp.id"
                    :selected="selected"
                    :isSelectable="listRFP.isSelectable || by_status.isSelectable"
                    :showEmailStatus="listRFP.name == 'Sent RFPS'"
                    :hasReminder="
                      hasReminder.includes(rfp.status.toLowerCase())
                    "
                    :emailHistoriesForRFPIds="emailHistoriesForRFPIds"
                    :historyLoading="historyLoading"
                    @toggleSelected="toggleSelected"
                    @resendRFP="resendRFP"
                    @reminderRFP="()=>reminderRFP({selected:[rfp]})"
                    @getData="getData"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center my-6 text-white">
        Congrats on creating your RFP you can add to hotels to your proposal by
        <button class="underline" @click="goToSearch">clicking here</button>
      </p>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {
  ListItemHotel,
  ListItemHotelSkeleton,
  GroupDialog,
  RoomNumbersDialog,
} from "@/components/rfps/hotels";
import moment from "moment";
import { emailHistoriesForRFPIds, sendRFP, sendReminderRFP } from "@/utils/rfp.jsx";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import Toggle from "@/components/ui/atoms/Toggle.vue";
import { rfpsApi } from "@/utils/apis/rfpsApi";

export default {
  props: ["rfp_hotels", "group_status", "rfp", "rfp_hotels_filtered"],
  components: {
    ListItemHotel,
    ListItemHotelSkeleton,
    GroupDialog,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
    RoomNumbersDialog,
    Toggle
  },
  data() {
    return {
      loadingReminder: false,
      showRoomNumbersDialog: false,
      showGroupDialog: false,
      showNoteDialog: false,
      showMenu: false,
      status: "All",
      selected: [],
      loadingSend: false,
      internalMessage: null,
      hasReminder: [
        "accepted by crewfare",
        "crewfare counter",
        "counter submitted",
        "bid viewed",
        "bid opened",
        "progress saved",
        "sent",
      ],
      hasInternalMessage: false,
      total: 0,
      search: "",
      activeLists: [],
      emailHistoriesForRFPIds: {},
      historyLoading: false,
      autoRemind: false
    };
  },
  watch:{
    filteredRFPsPhase3(){
      this.historyLoading = true
      const rfpIds = this.filteredRFPsPhase3.ids;
      emailHistoriesForRFPIds(rfpIds).then((emailHistories)=>{
        console.log("Email Histories", emailHistories)
        this.emailHistoriesForRFPIds = emailHistories;
        this.historyLoading = false
      })
      .catch((error)=>{
        console.error("Some error in fetching history",error);
        this.historyLoading = false
      });
    },
  },
  computed: {
    selectedRFPIds(){
      return this.selected.map((rfp)=>rfp.id)
    },
    signedHotels() {
      const filterHotels = this.rfp_hotels.filter(
        (rfp) => rfp.status.toLowerCase() === "signed by both sides"
      );
      return filterHotels.length;
    },
    signedHotelsList() {
      return this.rfp_hotels.filter(
        (rfp) => rfp.status.toLowerCase() === "signed by both sides"
      );
    },
    formatedDates() {
      const startDate = this.rfp.startDate;
      const endDate = this.rfp.endDate;
      return `${getMomentFromFirebaseDate(startDate).format(
        "ddd MMM. DD, YYYY"
      )} to ${getMomentFromFirebaseDate(endDate).format("ddd MMM. DD, YYYY")}`;
    },
    isAllSelected() {
      return this.selected.length === this.rfp_hotels_filtered.length;
    },
    listRfps() {
      if (this.selected.ids) return;
      const lists = [];
      let selectedIds = [];
      if (this.selected.ids) selectedIds = this.selected.ids;
      else selectedIds = this.selected.map((rfp) => rfp.id);
      if (this.filteredRFPsPhase7.total > 0) {
        lists.push({
          name: "Completed RFPS",
          active: false,
          lists: this.filteredRFPsPhase7,
          isSelectable: false,
        });
      }
      if (this.filteredRFPsPhase6.total > 0) {
        lists.push({
          name: "RFPS on signing process",
          active: false,
          lists: this.filteredRFPsPhase6,
          isSelectable: false,
        });
      }
      if (this.filteredRFPsPhase5.total > 0) {
        lists.push({
          list: 4,
          name: "Accepted RFPS",
          active: false,
          lists: this.filteredRFPsPhase5,
          isSelectable: false,
        });
      }
      if (this.filteredRFPsPhase4.total > 0) {
        lists.push({
          id: 3,
          name: "Negotiation RFPS",
          active: false,
          lists: this.filteredRFPsPhase4,
        });
      }
      if (this.filteredRFPsPhase3.total > 0) {
        const ids = this.filteredRFPsPhase3.ids;
        const allSelected = ids.every((id) => selectedIds.includes(id));
        lists.push({
          id: 1,
          name: "Sent RFPS",
          active: true,
          lists: this.filteredRFPsPhase3,

          allSelected,
          isSelectable: true,
        });
      }
      if (this.filteredRFPsPhase2.total > 0) {
        const ids = this.filteredRFPsPhase2.ids;
        const allSelected = ids.every((id) => selectedIds.includes(id));
        lists.push({
          id: 2,
          name: "Newly Added Hotels",
          active: false,
          lists: this.filteredRFPsPhase2,
          allSelected,
          isSelectable: true,
        });
      }
      if (this.filteredRFPsPhase1.total > 0) {
        lists.push({
          name: "Declined RFPS",
          active: false,
          lists: this.filteredRFPsPhase1,
          isSelectable: false,
        });
      }
      if (this.filteredRFPsPhase0.total > 0) {
        lists.push({
          name: "RFPs with issue",
          active: false,
          lists: this.filteredRFPsPhase0,
          isSelectable: false,
        });
      }
      return lists;
    },
    filteredRFPsPhase7() {
      const both = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "signed by both sides"
      );
      return {
        total: both.length,
        by_status: [
          {
            status: "signed by both sides",
            list: both,
          },
        ],
      };
    },
    filteredRFPsPhase6() {
      const newTerms = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "update bid terms"
      );
      const crewfare = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "signed by crewfare"
      );
      const hotel = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "signed by the hotel"
      );
      return {
        total: crewfare.length + hotel.length + newTerms.length,
        by_status: [
          {
            status: "update bid terms",
            list: newTerms,
          },
          {
            status: "signed by crewfare",
            list: crewfare,
          },
          {
            status: "signed by the hotel",
            list: hotel,
          },
        ],
      };
    },
    filteredRFPsPhase5() {
      const accepted = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "accepted contract updates"
      );
      const both = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "accepted by both sides"
      );
      const hotel = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "accepted by hotel"
      );
      const crewfare = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "accepted by crewfare"
      );
      return {
        total: crewfare.length + hotel.length + accepted.length + both.length,
        by_status: [
          {
            status: "accepted contract updates",
            list: accepted,
          },
          {
            status: "accepted by both sides",
            list: both,
          },
          {
            status: "accepted by hotel",
            list: hotel,
          },
          {
            status: "accepted by crewfare",
            list: crewfare,
            isSelectable: true,
            allSelected: this.isAllSelectedInList(crewfare)
          },
        ],
      };
    },
    filteredRFPsPhase4() {
      const reopenedCrewfare = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "reopened by crewfare"
      );
      const reopenedHotel = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "reopened by hotel"
      );
      const submited = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "bid submited"
      );
      const hotel = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "hotel countered"
      );
      const crewfare = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "crewfare counter"
      );
      return {
        total:
          crewfare.length +
          hotel.length +
          submited.length +
          reopenedCrewfare.length +
          reopenedHotel.length,
        by_status: [
          {
            status: "ropened by crewfare",
            list: reopenedCrewfare,
          },
          {
            status: "ropened by hotel",
            list: reopenedHotel,
          },
          {
            status: "counter submitted",
            list: crewfare,
            isSelectable: true,
            allSelected: this.isAllSelectedInList(crewfare)
          },
          {
            status: "hotel countered",
            list: hotel,
          },
          {
            status: "bid from hotel",
            list: submited,
          },
        ],
      };
    },
    filteredRFPsPhase3() {
      const viewed = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "bid viewed"
      );
      const opened = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "bid opened"
      );
      const progressSaved = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "progress saved"
      );
      const sent = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "sent"
      );
      return {
        total:
          viewed.length + opened.length + sent.length + progressSaved.length,
        ids: [
          ...viewed.map((item) => item.id),
          ...opened.map((item) => item.id),
          ...progressSaved.map((item) => item.id),
          ...sent.map((item) => item.id),
        ],
        by_status: [
          {
            status: "bid viewed",
            list: viewed,
          },
          {
            status: "bid opened",
            list: opened,
          },
          {
            status: "progress saved",
            list: progressSaved,
          },
          {
            status: "sent",
            list: sent,
          },
        ],
      };
    },
    filteredRFPsPhase2() {
      const newHotels = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "new"
      );
      return {
        ids: [...newHotels.map((item) => item.id)],
        total: newHotels.length,
        by_status: [
          {
            status: "new",
            list: newHotels,
          },
        ],
      };
    },
    filteredRFPsPhase1() {
      const hotel = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "declined by hotel"
      );
      const crewfare = this.rfp_hotels_filtered.filter(
        (rfp) => rfp.status.toLowerCase() === "declined by crewfare"
      );
      return {
        total: crewfare.length + hotel.length,
        by_status: [
          {
            status: "declined by hotel",
            list: hotel,
          },
          {
            status: "declined by crewfare",
            list: crewfare,
          },
        ],
      };
    },
    filteredRFPsPhase0() {
      const empty = this.rfp_hotels_filtered.filter((rfp) => rfp.status === "");
      return {
        total: empty.length,
        by_status: [
          {
            status: "empty",
            list: empty,
          },
        ],
      };
    },
    listStatus() {
      let status = this.rfp_hotels.map((item) => item.status);
      return new Set(status);
    },
    account() {
      return this.$store.state.account;
    },
    hotels() {
      return this.rfp.hotels;
    },
    hotelsCount() {
      if (!this.rfp_hotels) return 0;
      return this.rfp_hotels?.length;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async handleAutoRemindChange(autoRemind){
      await rfpsApi.updateAutoRemind(this.rfp.id, autoRemind)
    },
    isAllSelectedInList(list){
      const selectedIds = this.selected.map((rfp) => rfp.id);
      const ids = list.map((rfp) => rfp.id);
      return ids.every((id) => selectedIds.includes(id));
    },
    toggleActive(list) {
      if (this.activeLists.includes(list)) {
        this.activeLists.splice(this.activeLists.indexOf(list), 1);
      } else {
        this.activeLists.push(list);
      }
    },
    doSearch() {
      this.$emit("doSearch", this.search);
    },
    async reminderRFP({ selected }) {
      if (!confirm("Are you sure?")) return;
      this.loadingReminder = true;
      console.log("RFP in reminder", this.rfp)
      await sendReminderRFP(selected, this.rfp);
      this.getData();
      this.loadingReminder = false;
    },
    selectSection(id, by_status) {
      const selectedIds = this.selected.map((rfp) => rfp.id);
      if (id === 1) {
        const ids = this.filteredRFPsPhase3.ids;
        const allSelected = ids.every((id) => selectedIds.includes(id));
        if (allSelected) {
          this.selected = this.selected.filter(
            (selected) => !ids.includes(selected.id)
          );
        } else {
          const selected = [];
          this.filteredRFPsPhase3.by_status.forEach((status) =>
            selected.push(...status.list)
          );
          this.selected = selected;
        }
      }
      if (id === 2) {
        const ids = this.filteredRFPsPhase2.ids;
        const allSelected = ids.every((id) => selectedIds.includes(id));
        if (allSelected) {
          this.selected = this.selected.filter(
            (selected) => !ids.includes(selected.id)
          );
        } else {
          const selected = [];
          this.filteredRFPsPhase2.by_status.forEach((status) =>
            selected.push(...status.list)
          );
          this.selected = selected;
        }
      }
      if(by_status.isSelectable){
        const ids = by_status.list.map((rfp) => rfp.id);
        const allSelected = ids.every((id) => selectedIds.includes(id));
        if (allSelected) {
          this.selected = this.selected.filter(
            (selected) => !ids.includes(selected.id)
          );
        } else {
          this.selected = [
            ...this.selected,
            ...by_status.list.filter(rfp=>!selectedIds.includes(rfp.id))
          ];
        }
      }
    },
    async resendRFP(rfp) {
      if (!confirm("Are you sure?")) return;
      this.loadingSend = true;
      await sendRFP(this.rfp, this.account, rfp);
      this.getData();
      this.loadingSend = false;
    },
    toggleAll() {
      if (this.isAllSelected) {
        this.selected = [];
      } else {
        this.selected = [
          ...this.rfp_hotels_filtered.filter((rfp) =>
            [
              "new",
              "sent",
              "bid viewed",
              "bid opened",
              "progress saved",
            ].includes(rfp.status.toLowerCase())
          ),
        ];
      }
    },
    toggleSelected(rfp) {
      const selected = this.selected;
      const selectedIndex = selected.findIndex((hotel) => hotel === rfp);
      if (selectedIndex >= 0) {
        selected.splice(selectedIndex, 1);
      } else {
        selected.push(rfp);
      }
      this.selected = selected;
    },
    async getData() {
      this.$emit("getData");
      this.total = this.rfp_hotels.length;

      this.selected = [
        ...this.rfp_hotels_filtered.filter((rfp) =>
          [
            "new",
            "sent",
            "bid viewed",
            "bid opened",
            "progress saved",
          ].includes(rfp.status.toLowerCase())
        ),
      ];
      document.title = `Launchpad - Crewfare - RFP - ${this.rfp.name}`;
    },
    filterStatus() {
      if (this.status !== "All") {
        const filtered = this.rfp_hotels.filter(
          (rfp) => rfp.status === this.status
        );
        const parsed = filtered;
        this.rfp_hotels_filtered = parsed;
        this.selected = [
          ...this.rfp_hotels_filtered.filter((rfp) =>
            [
              "new",
              "sent",
              "bid viewed",
              "bid opened",
              "progress saved",
            ].includes(rfp.status.toLowerCase())
          ),
        ];
      } else {
        this.rfp_hotels_filtered = this.rfp_hotels;
      }
    },
    async sendRFP({ selected }) {
      if (!confirm("Are you sure?")) return;
      if (!selected) select = this.selected;
      this.loadingSend = true;
      await sendRFP(this.rfp, this.account, selected);
      await this.getData();
      this.loadingSend = false;
    },
    async goToSearch() {
      this.$store.commit("setSelectedRFP", {
        id: this.$route.params.id,
        name: this.rfp.name,
        hotels: await this.rfp.hotels,
      });
      let rfp_data = this.rfp?.data ? this.rfp.data() : {...this.rfp}
      router.push({
        name: "searchView",
        query: {
          startDate: getMomentFromFirebaseDate(rfp_data.startDate).format(
            "MM-DD-YYYY"
          ),
          endDate: getMomentFromFirebaseDate(rfp_data.endDate).format(
            "MM-DD-YYYY"
          ),
          search: rfp_data.search,
          lat: rfp_data?.location?.lat || "",
          lng: rfp_data?.location?.lng || ""
        },
      });
    },
  },
};
</script>
