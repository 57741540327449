<template>
  <div class="flex flex-col text-white p-6 gap-4">
    <div class="flex flex-col gap-2 bg-crewfareGreyDark p-4 rounded-md text-white">
      <div class="text-base font-bold">Default Contract Template</div>
      <TemplatesList />
    </div>
  </div>
</template>

<script>
import TemplatesList from './TemplatesList.vue';

export default {
  components: {
    TemplatesList,
  },
  mounted() {
    document.title = 'Launchpad - Manage Templates';
  },
};
</script>
