<template>
  <div v-if="account" class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <div class="flex gap-4 items-center">
        <router-link
          :to="{ name: 'hotelsGroupsList' }"
          class="material-symbols-rounded text-white hover:text-crewfareGreen"
        >
          chevron_left
        </router-link>
        <h1 class="text-xl text-white">Hotels - Groups - {{ title }}</h1>
      </div>
    </div>
    <div class="flex flex-col gap-2 rounded pb-4 relative">
      <div class="flex flex-col gap-4 w-full p-4">
        <div class="flex flex-col gap-2">
          <label class="text-white font-bold">Add Group Name</label>
          <input
            type="text"
            v-model.lazy="name"
            class="w-full bg-gray-700 rounded px-4 text-white"
          />
        </div>
        <div class="flex flex-col gap-2">
          <label class="text-white font-bold">Add Manager</label>
          <input
            type="text"
            placeholder="Search user"
            v-model="searchAccount"
            class="w-full bg-gray-700 rounded px-4 text-white"
          />

          <div
            v-if="accountsFiltered.length > 0"
            class="flex flex-col gap-2 mt-2"
          >
            <div
              v-for="account in accountsFiltered"
              :key="account.id"
              @click="addManager(account)"
              class="px-4 py-2 flex border border-gray-50 gap-4 items-center rounded hover:bg-gray-600 cursor-pointer"
            >
              <span
                class="rounded-full w-[34px] h-[34px] bg-crewfareGreen text-gray-900 flex justify-center items-center"
              >
                {{ getInitials(account.name) }}
              </span>
              <div class="flex flex-col gap-1 text-white">
                <p>{{ account.name }}</p>
                <p>{{ account.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="manager.length > 0" class="flex flex-col gap-2 mt-2">
          <div
            v-for="(account, index) in manager"
            :key="account.id"
            class="px-4 py-2 flex border border-gray-50 gap-4 items-center rounded hover:bg-gray-600 cursor-pointer"
          >
            <span
              class="rounded-full w-[34px] h-[34px] bg-crewfareGreen text-gray-900 flex justify-center items-center"
            >
              {{ getInitials(account.name) }}
            </span>
            <div class="flex flex-col gap-1 flex-grow text-white">
              <p>{{ account.name }}</p>
              <p>{{ account.email }}</p>
            </div>
            <div>
              <span
                @click="removeManager(index)"
                class="cursor-pointer text-red-400 material-symbols-rounded"
              >
                close
              </span>
            </div>
          </div>
        </div>

      <p class="px-4 text-xl text-white">Select hotels</p>
      <div
        class="flex p-4 items-center bg-gray-800 justify-between sticky top-0"
      >
        <div class="flex gap-2 items-center text-crewfareGreen">
          <strong class="rounded-full bg-black px-3 py-1">{{
            hotelsFound
          }}</strong>
          <p>Hotels</p>
          <div class="flex ml-10 gap-5 items-center">
            <Select
              v-model="selectedFilter"
              :options="availableOptions"
              placeholder="Add Filter"
              variant="dark"
              @update:modelValue="applyFilter"
            />
            <div v-if="selectedOption" class="ml-5 flex items-center">
              <label class="text-white mr-6">Filter by {{ selectedOption }}</label>
              <Select
                v-model="dynamicSelection"
                :options="formattedDynamicOptions"
                placeholder="Select an option"
                variant="dark"
              />
            </div>

          </div>
        </div>
        <div class="flex items-center gap-4 text-white">
          <input
            type="search"
            v-model="search"
            class="bg-gray-700 rounded px-4"
            placeholder="Search..."
          />
        </div>
      </div>
      <div>
        <div v-if="!loading">
          <table class="gap-4 mt-4 px-4 divide-y text-white w-full">
            <thead>
              <tr>
                <div class="ml-4 mb-4">
                  <span>{{ selectedHotels.length }} Hotels Selected</span>
                </div>
                <th class="flex py-2 items-center px-4 gap-4 text-left">
                  <span @click="toggleAll" class="flex w-[18px]">
                    <span
                      v-if="selectedAll"
                      class="text-gray-800 bg-crewfareGreen rounded w-[18px] h-[18px] flex items-center justify-center"
                    >
                     <img src="/icons/small-check.svg" />
                    </span>
                    <span
                      v-else
                      class="border rounded w-[18px] h-[18px]"
                    ></span>
                  </span>
                  Name
                </th>
                <th class="px-4 py-1 text-left">County</th>
                <th class="px-4 py-1 text-left">City</th>
                <th class="px-4 py-1 text-left">State</th>
              </tr>
            </thead>
            <tbody class="divide-y">
              <tr
                v-for="hotel in sliceHotels"
                :key="hotel.id"
                class="py-2 text-white"
              >
                <td class="flex py-2 items-center px-4 gap-4">
                  <span class="w-[18px] flex" @click="toggleHotel(hotel)">
                    <span
                      v-if="hotel.isSelected"
                      class="text-gray-800 bg-crewfareGreen rounded w-full h-[18px] flex items-center justify-center"
                    >
                      <img src="/icons/small-check.svg" />
                    </span>
                    <span v-else class="border rounded w-full h-[18px]"></span>
                  </span>
                  {{ hotel.data().name }}
                </td>
                <td class="px-4">{{ hotel.data().county }}</td>
                <td class="px-4">{{ hotel.data().city }}</td>
                <td class="px-4">{{ hotel.data().state }}</td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :max="16"
            :actual="actual"
            :total="filteredHotels.length"
            @togglePage="togglePage"
          />
        </div>
      </div>

      <div class="flex flex-col gap-1 w-full px-4 text-white">
      </div>

    </div>
    <div class="flex gap-4 p-4 justify-between">
      <router-link :to="{ name: 'hotelsGroupsList' }" class="cancel-button">
        Cancel
      </router-link>
      <button @click="save" :disabled="!valid || loading" class="green-button">
        <span
          v-if="loading"
          class="material-symbols-rounded animate-spin flex items-center"
        >
          autorenew
        </span>
        <span v-else> Save </span>
      </button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { Loader } from "@googlemaps/js-api-loader";
import { firestore } from "@/utils/firebase";
import {
  getDocs,
  getDoc,
  query,
  collection,
  doc,
  where,
  orderBy,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { ListItem, ListItemSkeleton } from "@/components/hotels";
import Pagination from "@/components/default/Pagination.vue";
import { accountsApi } from "@/utils/apis/accountsApi";
import Select from "@/components/ui/atoms/Select.vue";

export default {
  components: {
    ListItem,
    ListItemSkeleton,
    Pagination,
    Select,
  },
  computed: {
    formattedDynamicOptions() {
      return this.dynamicOptions.map((item) => ({
        name: item,
        value: item,
      }));
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    filteredHotels() {
      if (!this.selectedOption || !this.dynamicSelection) {
        return this.hotels;
      }

      const fieldMapping = {
        Chain: "chain",
        County: "county",
        State: "state",
        City: "city",
      };

      const field = fieldMapping[this.selectedOption];

      if (!field) {
        console.warn(`Invalid field mapping for selectedOption: ${this.selectedOption}`);
        return this.hotels;
      }

      return this.hotels.filter((hotel) => {
        const hotelValue = hotel.data()[field];
        return hotelValue === this.dynamicSelection.value;
      });
    },
    availableOptions() {
      const selectedLabels = this.dynamicFields.map(field => field.label);
      return this.options.filter(option => !selectedLabels.includes(option));
    },
    selectedAll() {
      return (
        this.filteredHotels.length > 0 &&
        this.filteredHotels.every(hotel => hotel.isSelected)
      );
    },
    valid() {
      return true;
    },
    title() {
      return this.$route.params.group_id ? "Edit" : "Create";
    },
    account() {
      return this.$store.state.account;
    },
    sliceHotels() {
      const sliceStart = this.actual * 15;
      return this.filteredHotels.slice(sliceStart, sliceStart + 15);
    },
    hotelsFound() {
      return this.filteredHotels.length;
    },
  },
  data() {
    return {
      selectedFilter: null,
      availableOptions: [
        { name: 'chain', value: 'Chain' },
        { name: 'county', value: 'County' },
        { name: 'state', value: 'State' },
        { name: 'city', value: 'City' },
      ],
      searchAccount: "",
      manager: [],
      search: "",
      actual: 0,
      range: [...Array(8).keys()],
      loading: false,
      loadingMore: false,
      hotels: [],
      city: "",
      state: "",
      county: "",
      name: "",
      accountsFiltered: [],
      selectedHotels: [],
      selectedOption: null,
      linesToImport: null,
      linesProccessed: 0,
      report: {},
      groupHotel: {},
      accounts: [],
      isOpen: false,
      options: ["Chain", "County", "State", "City"],
      dynamicFields: [],
      dynamicSelection: null,
      dynamicOptions: [],
      filteredHotels: [],
      fieldCounter: 0,
    };
  },
  watch: {
    selectedFilter(newVal) {
      if (newVal) {
        this.applyFilter(newVal.value);
      }
    },
    dynamicSelection(newVal) {
      if (!newVal) {
        this.filteredHotels = this.hotels;
      } else {
        const field = this.selectedOption.toLowerCase();
        this.filteredHotels = this.hotels.filter((hotel) => {
          const hotelData = hotel.data();
          return hotelData[field] === newVal;
        });
      }
    },
    search() {
      this.actual = 0;
    },
    selectedOption(newVal) {
      if (newVal) {
        this.generateDynamicOptions(newVal);
      } else {
        this.dynamicOptions = [];
      }
    },
    dynamicSelection(newVal) {
      this.updateFilteredHotels();
    },
    searchAccount() {
      if (this.searchAccount.length === 0) {
        this.accountsFiltered = [];
      } else {
        this.accountsFiltered = this.accounts
          .filter(
            (account) =>
              account.name.toLowerCase().includes(this.searchAccount.toLowerCase()) ||
              account.email.toLowerCase().includes(this.searchAccount.toLowerCase())
          )
          .slice(0, 5);
      }
    },
    search() {
      if (this.search.length) {
        this.filteredHotels = this.hotels.filter(
          (hotel) =>
            hotel
              .data()
              .name.toLowerCase()
              .indexOf(this.search.toLowerCase()) >= 0
        );
      } else {
        this.filteredHotels = this.hotels;
      }
      this.actual = 0;
    },
  },
  mounted() {
    this.getData();
    const apiKey = "AIzaSyCwDSzKEVg4LGxPAqvcyJTwngTj7OPFJ18";
    const loader = new Loader({
      apiKey,
    });

    loader.load().then(async () => {
      await google.maps.importLibrary("places");
    });
  },
  methods: {
    applyFilter(value) {
      this.selectedOption = value;
      this.generateDynamicOptions(value);
    },
    updateFilteredHotels() {
      const fieldMapping = {
        Chain: "chain",
        County: "county",
        State: "state",
        City: "city",
      };

      const field = fieldMapping[this.selectedOption];

      if (!field) {
        this.filteredHotels = this.hotels;
        return;
      }

      this.filteredHotels = this.hotels.filter((hotel) => {
        const value = hotel.data()[field];
        return value === this.dynamicSelection.value;
      });
    },
    getHotelField(hotel, filterLabel) {
      const fieldMapping = {
        Chain: "chain",
        County: "county",
        State: "state",
        City: "city",
      };
      return hotel.data()[fieldMapping[filterLabel]] || "";
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.dynamicSelection = null;
      this.generateDynamicOptions(option);
      this.isOpen = false;
    },
    generateDynamicOptions(option) {
      const fieldMapping = {
        Chain: "chain",
        County: "county",
        State: "state",
        City: "city",
      };

      const field = fieldMapping[option];
      if (!field) {
        console.error(`Campo no encontrado para la opción: ${option}`);
        return;
      }

      // Generar opciones únicas basadas en el campo
      this.dynamicOptions = Array.from(
        new Set(
          this.hotels.map((hotel) => hotel.data()[field]).filter(Boolean)
        )
      );

      console.log(`Opciones dinámicas generadas para ${field}:`, this.dynamicOptions);
    },
    selectOption(option) {
      this.selectedOption = option;
      this.dynamicSelection = null;
      this.generateDynamicOptions(option);
    },
    removeField(fieldId) {
      this.dynamicFields = this.dynamicFields.filter(field => field.id !== fieldId);
    },
    toggleAll() {
      if (this.selectedAll) {
        this.filteredHotels.forEach(hotel => {
          hotel.isSelected = false;
        });
        this.selectedHotels = [];
      } else {
        this.filteredHotels.forEach(hotel => {
          hotel.isSelected = true;
        });
        this.selectedHotels = this.filteredHotels.map(hotel => ({
          id: hotel.id,
          ...hotel.data(),
        }));
      }
    },
    isSelected(id) {
      return (
        this.selectedHotels.findIndex(
          (selectedHotel) => selectedHotel.id === id
        ) >= 0
      );
    },
    toggleHotel(hotel) {
      const index = this.selectedHotels.findIndex(
        (selectedHotel) => selectedHotel.id === hotel.id
      );
      if (index >= 0) {
        hotel.isSelected = false;
        this.selectedHotels.splice(index, 1);
      } else {
        hotel.isSelected = true;
        this.selectedHotels.push({ id: hotel.id, ...hotel.data() });
      }
    },
    addManager(account) {
      const id = this.manager.findIndex((manager) => manager.id === account.id);
      if (id >= 0) {
        return;
      }
      this.manager.push({ id: account.id, ...account });
      this.searchAccount = "";
    },
    removeManager(index) {
      this.manager.splice(index, 1);
    },
    getInitials(name) {
      const nameSplit = name.split(" ");
      const firstLetter = nameSplit[0];
      let lastLetter = "";
      if (nameSplit.length > 1) {
        const lastName = nameSplit.reverse()[0];
        lastLetter = lastName[0] || "";
      }
      return `${firstLetter[0]}${lastLetter}`;
    },
    togglePage(page) {
      this.actual = page;
    },
    async getData() {
      this.loading = true;
      const qHotels = query(collection(firestore, "hotels"), orderBy("name"));
      const hotels = await getDocs(qHotels);
      const hotelsDocs = hotels.docs;
      const accounts = await accountsApi.list({
        types: JSON.stringify(["group manager"]),
        page: 0,
        per_page: 500
      }).then((response) => {
        return response.data.data;
      });
      this.accounts = accounts;
      this.loading = false;
      if (this.$route.params.group_id) {
        const hotelGroupRef = doc(
          firestore,
          "hotel_groups",
          this.$route.params.group_id
        );
        this.groupHotel = await getDoc(hotelGroupRef);
        this.name = this.groupHotel.data().name;
        this.selectedHotels = this.groupHotel.data().hotels;
        const selectedHotelsIds = this.selectedHotels.map((hotel) => hotel.id);
        let hotels = hotelsDocs.map((hotel) => {
          hotel.isSelected = selectedHotelsIds.includes(hotel.id);
          return hotel;
        });
        let hotelsSorted = hotels
          .sort((a, b) => Number(a.isSelected) - Number(b.isSelected))
          .reverse();
        this.hotels = this.filteredHotels = hotelsSorted;
        this.manager = this.groupHotel.data().manager;
        document.title = `Launchpad - Crewfare - Hotel - Group - ${this.name}`;
      } else {
        document.title = `Launchpad - Crewfare - Hotel - Group - Add Hotel`;
        this.hotels = this.filteredHotels = hotelsDocs;
      }
    },
    async save() {
      if (!this.valid) return;
      this.loading = true;
      if (this.$route.params.group_id) {
        await setDoc(
          doc(firestore, "hotel_groups", this.$route.params.group_id),
          {
            ...this.groupHotel.data(),
            name: this.name,
            manager: this.manager,
            manager_ids: this.manager.map((manager) => manager.id),
            hotels: this.selectedHotels,
            updated_at: new Date(),
          }
        );
        this.$store.commit("setToast", {
          content: "Hotel Group Updated",
        });
      } else {
        const data = {
          name: this.name,
          manager: this.manager,
          manager_ids: this.manager.map((manager) => manager.id),
          hotels: this.selectedHotels,
          created_at: new Date(),
        };
        await addDoc(collection(firestore, "hotel_groups"), data);
        this.$store.commit("setToast", {
          content: "Hotel Group Added",
        });
      }
      router.push({ name: "hotelsGroupsList" });
      this.loading = false;
    },
    props: {
      hotels: Array,
    },
  },
};
</script>
