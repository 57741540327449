<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_4314_42803" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_4314_42803)">
        <path d="M5 15.8337H7.78854V11.635C7.78854 11.4216 7.86069 11.2427 8.005 11.0983C8.14944 10.954 8.32833 10.8818 8.54167 10.8818H11.4583C11.6717 10.8818 11.8506 10.954 11.995 11.0983C12.1393 11.2427 12.2115 11.4216 12.2115 11.635V15.8337H15V8.46184C15 8.4192 14.9906 8.38052 14.9719 8.34579C14.9533 8.31107 14.9279 8.28031 14.8958 8.2535L10.1523 4.68788C10.1095 4.65052 10.0587 4.63184 10 4.63184C9.94125 4.63184 9.89049 4.65052 9.84771 4.68788L5.10417 8.2535C5.07208 8.28031 5.04674 8.31107 5.02813 8.34579C5.00938 8.38052 5 8.4192 5 8.46184V15.8337ZM3.75 15.8337V8.46184C3.75 8.22336 3.80333 7.99746 3.91 7.78413C4.01681 7.57066 4.16431 7.39489 4.3525 7.25684L9.09625 3.68309C9.35944 3.48225 9.66028 3.38184 9.99875 3.38184C10.3372 3.38184 10.6389 3.48225 10.9037 3.68309L15.6475 7.25684C15.8357 7.39489 15.9832 7.57066 16.09 7.78413C16.1967 7.99746 16.25 8.22336 16.25 8.46184V15.8337C16.25 16.1745 16.1269 16.4681 15.8806 16.7143C15.6344 16.9606 15.3408 17.0837 15 17.0837H11.7148C11.5013 17.0837 11.3224 17.0115 11.1781 16.867C11.0337 16.7227 10.9615 16.5439 10.9615 16.3304V12.1318H9.03854V16.3304C9.03854 16.5439 8.96632 16.7227 8.82188 16.867C8.67757 17.0115 8.49868 17.0837 8.28521 17.0837H5C4.65917 17.0837 4.36562 16.9606 4.11937 16.7143C3.87312 16.4681 3.75 16.1745 3.75 15.8337Z" fill="currentColor"/>
        </g>
    </svg>
</template>
<script>
export default {}
</script>