<template>
   <div class="flex flex-col bg-lpWhite p-[1rem] rounded-[0.75rem] w-[314px] gap-y-[8px]">
        <img :src="icon" alt="feature icon" class="w-[1.2rem] h-[1.2rem]" />
        <div class="flex flex-col gap-y-[4px]">
            <Text :variant="'h3'" :color="'black'" :thickness="'semiBold'">{{ title }}</Text>
            <Text :variant="'p'" :color="'gray'" :thickness="'normal'">{{ content }}</Text>
        </div>
   </div> 
</template>
<script lang="ts">
import Text from '../../atoms/Text.vue';

export default {
    components: {
        Text
    },
    props:{
        title: String,
        content: String,
        icon: {
            type: String,
            default: '/icons/feature-icon.svg'
        }
    }
}
</script>