import store from '@/store';

export const lpAlert = (message: string, autoClose: number = 0) => {
  store.commit('addAlert', { message, autoClose });
};

export const lpPrompt = async (title: string, subtitle: string) => {
  return new Promise<string>(resolve => {
    store.commit('addPrompt', { title, subtitle, callback: resolve });
  });
};
