<template>
  <div class="text-white px-6 py-3 bg-[#23262F]">
    <Breadcrumb
      icon="th-large"
      :items="['Search', selectedRFP && selectedRFP.name]"
    />
    <div class="bg-[#141416] p-4 mt-6 flex flex-col gap-4 rounded-lg relative">
      <SearchFilters
        :loading="loading"
        @doSearch="getData"
        @setLatLng="setLatLng"
      />
      <Loading v-if="loading" />
      <div v-else class="flex flex-col gap-4">
        <SearchResults
          v-if="!hotel || !assignRFP"
          v-show="payload.dates && type === 'list' && !hotel && !assignRFP"
          :listHotels="list"
          :found="found"
          :assignRFP="assignRFP"
          :selectedHotelsEmpty="selectedHotelsEmpty"
          :actual="page_number"
          :chains="chains"
          @sortSearch="getData"
          @togglePage="togglePage"
          @inputChanged="handleInputChange"
        />
        <SearchContacts v-if="hotel" :hotel="hotel" @update="getData" />
        <SearchRfps />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/default/Breadcrumb.vue";
import { firestore } from "@/utils/firebase";
import { getDocs, query, collection } from "firebase/firestore";
import {
  SearchResults,
  SearchContacts,
  SearchRfps,
  SearchMap,
  SearchFilters,
  ToggleType,
} from "@/components/search";
import moment from "moment";
import Dinero from "dinero.js";
import Loading from "@/components/default/Loading.vue";
import { hotelsApi } from "@/utils/apis/hotelsApi";
import { capitalize } from "@crewfare/utils";

export default {
  components: {
    Breadcrumb,
    SearchFilters,
    SearchResults,
    SearchContacts,
    SearchRfps,
    SearchMap,
    ToggleType,
    Loading,
  },
  data() {
    return {
      type: "list",
      originalList: [],
      frontendFilterActive: false,
      list: [],
      found: null,
      selectedCount: 0,
      payload: {},
      loading: false,
      latLng: "",
      hotels: null,
      page_number: 0,
      chains: [],
    };
  },
  computed: {
    selectedRFP() {
      return this.$store.state.selectedRFP;
    },
    assignRFP() {
      return this.$store.state.assignRFP;
    },
    account() {
      return this.$store.state.account;
    },
    hotel() {
      return this.$store.state.hotel;
    },
    selectedHotelsEmpty() {
      return this.$store.state.selectedHotels?.length === 0 || false;
    },
  },
  async mounted() {
    document.title = `Launchpad - Crewfare - Search`;
  },
  beforeUnmount() {
    this.hotels = null;
    this.$store.commit("setSearch", null);
    this.$store.commit("setSelectedRFP", null);
    this.$store.commit("setHotelsIdWithContact", []);
  },
  methods: {
    togglePage(page) {
      this.page_number = page;
    },
    setLatLng(item) {
      this.latLng = item;
    },
    setResults({ list, found }) {
      this.found = found;
      this.list = list;
    },
    handleInputChange(value) {
      this.frontendFilterActive = !!value;
      this.filterList(value);
    },
    filterList({ value, chain }) {
      if (!value) {
        this.list = this.originalList.filter((item) => {
          const chainName = item.data?.().chain || "";
          return chainName.toLowerCase().includes(chain.toLowerCase());
        });
      } else if (!chain) {
        this.list = this.originalList.filter((item) => {
          const name = item.name || item.data?.().name || "";
          return name.toLowerCase().includes(value.toLowerCase());
        });
      } else {
        this.list = this.originalList.filter((item) => {
          const name = item.name || item.data?.().name || "";
          const chainName = item.data?.().chain || "";
          return (
            name.toLowerCase().includes(value.toLowerCase()) &&
            chainName.toLowerCase().includes(chain.toLowerCase())
          );
        });
      }
    },
    distance(lat1, lon1, lat2, lon2) {
      const R = 6371e3;
      const φ1 = (lat1 * Math.PI) / 180;
      const φ2 = (lat2 * Math.PI) / 180;
      const Δφ = ((lat2 - lat1) * Math.PI) / 180;
      const Δλ = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return (R * c) / 1609;
    },
    async searchUsingAmadeus(payload) {
      const { lat, lng, dates, radius, rating, minPrice, maxPrice } = payload;
      const checkIn = moment(dates[0]).format("YYYY-MM-DD");
      const checkOut = moment(dates[1]).format("YYYY-MM-DD");
      const amadeus_search_url = import.meta.env.VITE_AMADEUS_SEARCH_URL;
      const data = await fetch(amadeus_search_url, {
        method: "POST",
        body: JSON.stringify({
          checkIn,
          checkOut,
          lat,
          lng,
          radius,
          minPrice,
          maxPrice,
          awards: rating?.split(",") || [],
        }),
      });
      let hotels = await data.json();
      hotels = hotels.map((hotel) => ({
        ...hotel,
        star: hotel.rating !== "NA" ? parseFloat(hotel.rating) : 0,
        price: {
          lead: {
            formatted: Dinero({ amount: hotel.startingPrice | 0 }).toFormat(
              "$0.00"
            ),
            amount: hotel.startingPrice / 100,
          },
        },
        propertyImage: {
          image: {
            url: hotel.featuredImage ? hotel.featuredImage[0]?.URL : "",
          },
        },
      }));
      return hotels;
    },
    async getData() {
      // if (this.frontendFilterActive) {
      //   return;
      // }
      this.loading = true;
      const qHotels = query(collection(firestore, "hotels"));
      const hotelList = await getDocs(qHotels);
      let hotels = hotelList.docs;
      this.payload = this.$store.state.search;
      const startDate = `${this.payload.dates[0].getFullYear()}-${
        this.payload.dates[0].getMonth() + 1
      }-${this.payload.dates[0].getDate()}`;
      const endDate = `${this.payload.dates[1].getFullYear()}-${
        this.payload.dates[1].getMonth() + 1
      }-${this.payload.dates[1].getDate()}`;
      let data = [];
      if (this.payload.searchProvider === "amadeus") {
        data = await this.searchUsingAmadeus(this.payload);
      } else if (this.payload.searchProvider === "hotel.com") {
        try{
        const result = await fetch(
          `https://hotels-com-provider.p.rapidapi.com/v2/hotels/search?domain=US&available_filter=SHOW_AVAILABLE_ONLY&sort_order=${this.payload.sorting}&locale=en_US&checkout_date=${endDate}&adults_number=1&checkin_date=${startDate}&price_min=${this.payload.minPrice}&price_max=${this.payload.maxPrice}&region_id=${this.payload.regionId}&star_rating_ids=${this.payload.rating}&page_number=${this.payload.page_number}`,
          {
            method: "GET",
            headers: {
              "X-RapidAPI-Host": "hotels-com-provider.p.rapidapi.com",
              "X-RapidAPI-Key":
                "572ef5b2admshbb85ad0c7ce456cp185401jsn722424625e07",
            },
          }
        );
        let hotelResults = await result.json();
        data = hotelResults.properties;
        }catch(e){
          console.log("Some error occured while fetching hotels from hotel.com", e)
          data = []
        }
      }
      //Filter the hotels based on the price range
      data = data?.filter(
        (hotel) =>
          hotel.price?.lead.amount >= parseFloat(this.payload.minPrice) &&
          hotel.price?.lead.amount <= parseFloat(this.payload.maxPrice)
      ).map((hotel) => {
        return {
          ...hotel,
          name: hotel.name.trim(),
        }
      });
      //Convert RFP Hotel to set
      const rfpHotels = new Set(this.selectedRFP?.hotels || []);
      //Remove the hotels that are already in the RFP from hotels
      if (this.selectedRFP && this.selectedRFP.hotels) {
        const existingHotelsNames = new Set(hotels
          .filter((hotel) => rfpHotels.has(hotel.id))
          .map((hotel) => hotel.data().name));
        data = data?.filter(
          (hotel) => !existingHotelsNames.has(hotel.name)
        );
        hotels = hotels.filter(
          (hotel) => !existingHotelsNames.has(hotel.data().name)
        );
      }
      //Add the distance from the event to the hotels
      hotels.map((hotel, index) => {
        const distance = this.distance(
          hotel.data().lat,
          hotel.data().lng,
          this.payload.lat,
          this.payload.lng
        );
        hotel.hasContact = hotel.data().contacts?.length > 0;
        hotel.distance = distance;
      });
      //Convert Fetched Hotels to name based map
      const fetchedHotelsNameMap = {};
      data.forEach((hotel) => {
        fetchedHotelsNameMap[hotel.name.trim()] = hotel;
      });
      //Filterout database hotel based on distance
      const hotelsList = hotels.filter((hotel) => hotel.distance < this.payload.radius);
      //Add distance to the hotels fetched from API
      data?.map(
        (hotel) =>
          (hotel.distance =
            parseFloat(hotel?.distanceFromEvent) ||
            hotel?.destinationInfo?.distanceFromDestination?.value ||
            0)
      );
      //Filter the api fetched hotels based on the distance
      data = data?.filter(
        (hotel) => hotel.distance < this.payload.radius
      );

      // Add the hotels from the database to the hotels fetched from the API
      const mergedData = []
      hotelsList.forEach((hotel, index) => {
        const hotelKey = hotel.data().name?.trim();
        const hasHotel = !!fetchedHotelsNameMap[hotelKey];
        if (!hasHotel) {
          hotel.exists = true;
          mergedData.push(hotel);
        } else{
          fetchedHotelsNameMap[hotelKey].crewfare = hotel;
          fetchedHotelsNameMap[hotelKey].exists = hotel.data().contacts?.length > 0;
          fetchedHotelsNameMap[hotelKey].id = hotel.id;
          mergedData.push(fetchedHotelsNameMap[hotelKey]);
        }
      });
      const savedHotelListNames = new Set(hotelsList.map((hotel) => hotel.data().name.trim()));
      data = mergedData
      //Add the hotels that are not in the database to the list
      for(let hotelKey in fetchedHotelsNameMap){
        if(!savedHotelListNames.has(hotelKey)){
          data.push(fetchedHotelsNameMap[hotelKey])
        }
      }
      const hotelsWithContactId = data
        .filter((hotel) => hotel.exists && hotel.hasContact)
        .map((hotel) => hotel.id);
      this.$store.commit("setHotelsIdWithContact", hotelsWithContactId);
      const list = data || [];
      let listSorted;
      if (this.payload.sorting === "DISTANCE") {
        listSorted = list.sort((a, b) => {
          const distanceA = Number(a.distance);
          const distanceB = Number(b.distance);

          if (!isNaN(distanceA) && !isNaN(distanceB)) {
            return distanceA - distanceB;
          } else {
            return 0;
          }
        });
      } else {
        listSorted = list.sort((a, b) => {
          const priceA = a.price && Number(a.price.lead);
          const priceB = b.price && Number(b.price.lead);

          if (!isNaN(priceA) && !isNaN(priceB)) {
            return priceA - priceB;
          } else {
            return 0;
          }
        });
      }
      this.chains = (await hotelsApi.listChains().then((res) => res.data || []))
        .filter(Boolean)
        .map((chain) => capitalize(chain))
        .sort((a, b) => a.localeCompare(b));
      this.originalList = listSorted;
      this.list = [...this.originalList];
      this.found = list.length;
      this.page_number = 0;
      this.loading = false;
    },
  },
};
</script>
