import { BaseApi, getToken } from './apiFactory';

class RfpsApi extends BaseApi {
  listProposals = async (rfpId: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${rfpId}/proposals`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  updateAutoRemind = async (rfpId: string, autoRemind: Boolean) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${rfpId}/autoRemind`,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        autoRemind,
      },
    });
  };
}

export const rfpsApi = new RfpsApi({ name: 'rfps' });
