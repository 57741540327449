import { BaseApi, getToken } from './apiFactory';

class ProposalsAPI extends BaseApi {
  async send(proposalIds: string[], rfpId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/send`,
      method: 'POST',
      data: { proposalIds, rfpId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async remind(proposalIds: string[], rfpId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/remind`,
      method: 'POST',
      data: { proposalIds, rfpId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async bid(proposalId: string, data: any) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${proposalId}/bid`,
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async accept(proposalId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${proposalId}/accept`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async decline(proposalId: string, reasonDecline: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${proposalId}/decline`,
      method: 'POST',
      data: { reasonDecline },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async reopen(proposalId: string) {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/${proposalId}/reopen`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export const proposalsApi = new ProposalsAPI({ name: 'proposals' });
