<template>
  <div class="flex flex-col gap-2 w-full">
    <div class="flex flex-col gap-1">
      <div v-if="hasContacts">
        <div class="text-base truncate">{{ firstContact.name || 'No Name' }}</div>
        <div class="text-sm truncate">{{ firstContact.email || 'No Email' }}</div>
      </div>
      <div v-else>
        <div class="text-base truncate">No Contact</div>
      </div>
      <div v-if="hasAdditionalContacts" class="flex gap-1 items-center cursor-pointer select-none" @click="toggleContacts">
        <img :src="contactArrowSource" class="w-4 h-4" />
        <div class="text-sm truncate">
          <span class="text-crewfareGreen">{{ additionalContactsCount }}</span>{{ ` Additional Contact${addPlural}` }}
        </div>
      </div>
      <div v-if="showContacts" class="flex flex-col gap-1">
        <div
          v-for="(contact, index) in contacts.slice(1)"
          :key="index"
          class="text-sm truncate"
        >
          <div>{{ contact.name || 'No Name' }}</div>
          <div>{{ contact.email || 'No Email' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showContacts: false,
    };
  },
  computed: {
    hasContacts() {
      return Array.isArray(this.contacts) && this.contacts.length > 0;
    },
    firstContact() {
      return this.contacts[0] || {};
    },
    hasAdditionalContacts() {
      return this.contacts.length > 1;
    },
    additionalContactsCount() {
      return this.contacts.length - 1;
    },
    addPlural() {
      return this.additionalContactsCount > 1 ? 's' : '';
    },
    contactArrowSource() {
      return this.showContacts ? '/icons/i-arrow-down.svg' : '/icons/i-arrow-right.svg';
    },
  },
  methods: {
    toggleContacts() {
      this.showContacts = !this.showContacts;
    },
  },
};
</script>
