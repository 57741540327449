<template>
    <div class="flex flex-col w-full py-[1.25rem] gap-y-[16px] max-[640px]:items-center">
        <Text :variant="'h2'" :color="'black'" :thickness="'semiBold'"> Key Features</Text>
        <FeatureCard v-for="feature in features" :key="feature.title" :title="feature.title" :content="feature.content" />
    </div>
</template>
<script lang="ts">
import Text from '../../atoms/Text.vue';
import FeatureCard from './FeatureCard.vue';

export default {
    components: {
        Text,
        FeatureCard
    },
    data() {
        return {

            features: [
                {
                    title: 'Free to Use',
                    content: 'Access all platform features at no cost—no setup fees, no subscriptions.'
                },
                {
                    title: 'Streamlined RFP Management',
                    content: 'Effortlessly receive, review, and respond to RFPs for events, saving time and simplifying communication.'
                },
                {
                    title: 'Negotiation Made Easy',
                    content: 'Adjust offers, counter bids, and finalize deals directly within the platform.'
                },
                {
                    title: 'Real-Time Updates',
                    content: 'Easily manage room availability, pricing, and amenities to keep your listings competitive.'
                },
                {
                    title: 'Boost Hotel Visibility',
                    content: 'Get featured in curated event listings to attract more bookings from targeted audiences.'
                }
            ]
        };
    }
}
</script>