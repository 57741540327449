<template>
    <div class="flex flex-col gap-0">
        <!--Table head-->
        <div class="flex gap-4 p-4">
            <div v-for="column in columns" :key="column.key" :style="column.width ? {width: column.width + 'px'}: {}" class="font-semibold flex-1 px-2">{{ column.name }}</div>
            <div v-if="actions.length > 0" class="font-semibold flex-1">Actions</div>
        </div> 
        <!--Table body-->
        <div class="flex flex-col gap-2 bg-crewfareGrey rounded-lg p-4">
            <div v-if="loading" class="flex justify-center">
                <Spinner />
            </div>
            <div v-else v-for="row in rows" :key="row.id" class="flex gap-4">
                <TableRow :columns="columns" :row="row" :actions="actions"/>
            </div>
        </div>
    </div>

</template>
<script lang="ts">
import { PropType } from 'vue';
import Spinner from '../../atoms/Spinner.vue';
import TableRow from './TableRow.vue';
export interface Column{
    key: string,
    name: string,
    width?: number,
    transform?: (row: any) => string
    errorText?: (row: any) => string
}
// Create a Row type that ensures all `columns.key` properties exist in the row
export type Row<C extends Column[]> = {
  [K in C[number]['key']]: string | number; // Replace `any` with stricter typing if needed (e.g., `string | number`)
};
type LoadingRowIds = {
    [id: string]: string
}
export default {
    props : {
        loading: Boolean,
        columns: {
            type: Array as PropType<Column[]>,
            required: true
        },
        rows: {
            type: Array as PropType<Row<Column[]>[]>,
            required: true,
        },
        actions:{
            type: Array as PropType<{
                key: string,
                label: string,
                callback: (row: Row<Column[]>) => void,
                variant: 'primary' | 'secondary' | 'danger'
            }[]>,
            default: () => []
        }
    },
    components: {
        Spinner,
        TableRow
    }
}
</script>