import router from '@/router';
import { firestore } from '@/utils/firebase';
import { doc, addDoc, collection, setDoc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { sendNotificationAdmin, sendNotificationHotels } from '@/utils/notifications.jsx';
import { sendEmail, processVariables } from '@/utils/emails.jsx';
import { addChat } from '@/utils/messages.jsx';
import { updateHotelContacts } from '@/utils/hotel.jsx';
import store from '@/store';
import moment from 'moment';
import { getIdToken } from './user';
import { hotelInviteEmailContent, hotelInviteSubject } from '@/emails/hotel-invite';
import { nsoRfpInviteEmailContent, nsoRfpInviteSubject } from '@/emails/nso-rfp-invite';
import { reminderAcceptedEmailContent, reminderAcceptedSubject } from '@/emails/reminder-accepted';
import { bidReopenedInternalEmailContent, bidReopenedInternalSubject } from '@/emails/bid-reopen-internal';
import { bidReopenedHotelEmailContent, bidReopenedHotelSubject } from '@/emails/bid-reopen-hotel';
import { bidDeclinedInternalEmailContent, bidDeclinedInternalSubject } from '@/emails/bid-declined-internal';
import { bidDeclinedHotelEmailContent, bidDeclinedHotelSubject } from '@/emails/bid-declined-hotel';
import { reminderEmailContent, reminderSubject } from '@/emails/reminder';
import { rfpsApi } from './apis/rfpsApi';
import { getDateObjFromFirebaseDate, getMomentFromFirebaseDate } from './dateUtils';
import { convertArrayToKeyMappedObjectArrays } from '@crewfare/commons/src/utils';
import { reminderCounterEmailContent, reminderCounterSubject } from '@/emails/reminder-crewfare-counter';
import { proposalsApi } from './apis/proposalsApi';
import { emailsApi } from './apis/emails';
import { NotificationTypes } from '@/enums/NotificationTypes';
import { ProposalStatus } from '@crewfare/commons/src/model';
import { ViewsLocations } from '@crewfare/server-shared';

const statusList = [
  'sent',
  'bid viewed',
  'bid opened',
  'progress saved',
  'signed by both sides',
  'signed by the hotel',
  'signed by crewfare',
  'hotel countered',
  'crewfare counter',
  'accepted by hotel',
  'accepted by crewfare',
  'accepted by both sides',
  'declined by hotel',
  'declined by crewfare',
  'reopened by crewfare',
  'reopened by hotel',
  'bid submited',
  'update bid terms',
  'accepted contract updates',
];

const contractPhase = [
  'accepted by hotel',
  'accepted by crewfare',
  'accepted by both sides',
  'signed by the hotel',
  'signed by both sides',
  'update bid terms',
  'accepted contract updates',
];

const groupStatus = {
  signed: ['signed by both sides', 'signed by crewfare', 'signed by the hotel'],
  accepted: ['accepted by both sides'],
  sent: ['bid opened', 'progress saved', 'bid viewed', 'sent'],
  new: ['new'],
  declined: ['declined by hotel', 'declined by crewfare'],
  negotiation: [
    'reopened by crewfare',
    'reopened by hotel',
    'accepted by hotel',
    'accepted by crewfare',
    'bid submited',
    'crewfare counter',
    'hotel countered',
    'reopened by hotel',
    'reopened by crewfare',
    'update bid terms',
    'accepted contract updates',
  ],
};

const sendSingleRFP = async (rfp, email, user_name, account_id) => {
  const rfpHotelRef = doc(firestore, 'rfp_hotel', rfp.id);
  const rfpHotelData = await getDoc(rfpHotelRef);
  const startDate = rfpHotelData.data().startDate;
  const endDate = rfpHotelData.data().endDate;
  const formatedDate = `${moment(startDate.toDate()).format('dddd MMMM, DD, YYYY')} to ${moment(
    endDate.toDate(),
  ).format('dddd MMMM, DD, YYYY')}`;
  const responseDueDateFormated = getMomentFromFirebaseDate(rfpHotelData.data().responseDueDate).format(
    'dddd, MMMM DD',
  );
  const nights = rfpHotelData.data().roomList.length;
  const rooms = rfpHotelData.data().roomList[0].rooms.reduce((acc, room) => acc + parseFloat(room.amount), 0);
  const link = `${import.meta.env.VITE_URL}/rfp/${rfpHotelData.id}/view?accountId=${account_id}`;
  const data = rfpHotelData.data();
  await sendEmail({
    replyTo: rfpHotelData.data().replyTo,
    storeMail: { type: 'rfp_invite', rfp_id: rfpHotelData.id },
    to: email,
    template: 'emails.new-default',
    banner: rfpHotelData.data().banner,
    content: processVariables(
      hotelInviteEmailContent(data.estimated_attendees, nights, getLocationDenotor(data.search), rooms),
      {
        ...data,
        responseDueDateFormated,
        formatedDate,
        nights,
        link,
        user_name,
        rooms,
        venue: getLocationDenotor(data.search),
      },
    ),
    subject: processVariables(hotelInviteSubject, rfpHotelData.data()),
  });
};

const sendRFP = async (rfp, account, rfps) => {
  const rfp_data = rfp.data ? rfp.data() : rfp;
  const sent = await proposalsApi
    .send(
      rfps.map(rfp => rfp.id),
      rfp_data.id,
    )
    .then(response => response.data || {});
  console.log('Sent', sent);
  let countSentRfps = sent.totalMailsSent || 0;
  sendNotificationAdmin({
    type: NotificationTypes.NEW_RFP,
    content: `${rfp_data.name} just has been sent to ${countSentRfps} ${countSentRfps > 1 ? 'hotels' : 'hotel'} by ${
      account.name
    }`,
    to: { name: ViewsLocations.RFP_HOTELS, params: { id: rfp.id } },
  });
  store.commit('setToast', {
    content: `<p>${countSentRfps} out of total ${rfps.length}  ${rfps.length > 1 ? 'RFPs' : 'RFP'} has been sent!</p>`,
  });
  if (sent.errors?.length) {
    let errors = sent.errors.join('\n');
    alert('Following issue/s occured while sending the RFP\n' + errors);
  }
};

const createSimpleRFP = async (copyFrom = '') => {
  const rfp = await rfpsApi.create({ copyFrom }).then(response => response.data || {});
  router.push({ name: 'rfpsForm', params: { id: rfp.id }, query: { isNew: true } });
};

const RFPStatusPill = (status, isHotel) => {
  status = status.toLowerCase();
  if (status === '') return;
  let classColor = 'bg-gray-700';
  if (['signed by both sides', 'signed by crewfare', 'signed by the hotel', 'accepted by both sides'].includes(status))
    classColor = 'bg-crewfarePurple text-white';
  if (['accepted by hotel', 'accepted by crewfare'].includes(status)) classColor = 'bg-sky-400 text-white';
  if (['bid opened', 'bid viewed', 'progress saved'].includes(status)) classColor = 'bg-yellow-600 text-white';
  if (['sent'].includes(status)) classColor = 'bg-black text-white';
  if (['draft', 'new'].includes(status)) classColor = 'bg-white text-black';
  if (['declined by hotel', 'declined by crewfare'].includes(status)) classColor = 'bg-red-600 text-white';
  if (
    ['update bid terms', 'crewfare counter', 'hotel countered', 'reopened by crewfare', 'reopened by hotel'].includes(
      status,
    )
  )
    classColor = 'bg-white text-crewfarePurple';
  if (isHotel && status === 'sent') status = 'received';
  if (!isHotel && status === 'crewfare counter') {
    status = 'Counter submitted';
    classColor = 'bg-crewfareGreen text-gray-900';
  }
  if (isHotel && status === 'crewfare counter') {
    status = 'Counter from crewfare';
  }
  if (!isHotel && status === 'hotel countered') {
    status = 'Counter from hotel';
  }
  if (isHotel && status === 'hotel countered') {
    status = 'Counter submitted';
    classColor = 'bg-crewfareGreen text-gray-900';
  }
  if (status === 'bid submited' && !isHotel) {
    status = 'bid from hotel';
    classColor = 'bg-crewfareGreen text-gray-900';
  }
  if (status === 'bid submited' && isHotel) {
    status = 'Bid sent';
    classColor = 'bg-crewfareGreen text-gray-900';
  }
  if (status.includes('accepted')) {
    status = 'Accepted';
  }
  return `<span class="px-2 py-1 leading-6 rounded-full text-xs uppercase whitespace-nowrap ${classColor}">
      ${status}
    </span>`;
};

const forwardRFP = async (rfp, email, name) => {
  const qAccounts = query(collection(firestore, 'accounts'), where('email', '==', email));
  const accounts = await getDocs(qAccounts);
  let account;
  if (accounts.docs.length === 0) {
    account = await addDoc(collection(firestore, 'accounts'), {
      name,
      email,
      permissions: '',
      owner: '',
      type: 'Contact',
      created_at: new Date(),
    });
  } else {
    account = accounts.docs[0];
  }

  const hotel_id = rfp.hotel_id;
  const qContacts = query(
    collection(firestore, 'accounts'),
    where('email', '==', email),
    where('hotel_id', '==', hotel_id),
  );
  const contacts = await getDocs(qContacts);
  if (contacts.empty)
    await addDoc(collection(firestore, 'contacts'), {
      name,
      email,
      hotel_id,
      account_id: account.id,
      created_at: new Date(),
      type: 'Contact',
    });

  sendSingleRFP(rfp, email, name, account.id);
  await updateHotelContacts(hotel_id);
};

const setStatusHistory = async ({ data, content, status, route }) => {
  if (!data['status_history']) data['status_history'] = [];
  let account;
  if (!store.state.account) {
    const accountRef = doc(firestore, 'accounts', route.query.accountId);
    const accountObj = await getDoc(accountRef);
    account = accountObj.data();
  } else {
    account = store.state.account;
  }
  data['status_history'].push({
    created_at: new Date(),
    content,
    status,
    account,
  });
  const hotelRef = doc(firestore, 'hotels', data.hotel_id);
  const hotelData = await getDoc(hotelRef);
  const dataHotel = hotelData.data();
  if (!dataHotel['status_history']) dataHotel['status_history'] = [];
  dataHotel['status_history'].push({
    created_at: new Date(),
    content,
    status,
    account,
  });
  await setDoc(doc(firestore, 'hotels', data.hotel_id), {
    ...dataHotel,
  });
  return data;
};

const sendReminderRFP = async (proposals, rfp) => {
  const proposalIds = proposals.map(proposal => proposal.id);
  const response = await proposalsApi.remind(proposalIds, rfp.id).then(_ => _.data);
  let countSentRfps = response.totalMailsSent || 0;
  store.commit('setToast', {
    content: `<p>You just sent ${countSentRfps} ${countSentRfps > 1 ? 'reminders' : 'reminder'} out of ${
      proposals.length
    }</p>`,
  });
};

const saveField = async (rfp, history, field, name, history_value) => {
  const account = store.state.account;
  const rfpSession = store.state.rfpSession;
  rfpSession.id = rfp.id;
  const currentRFP = store.state.currentRfp[rfp.id];
  const oldValue = ['roomList', 'roomTypes'].includes(name) ? JSON.stringify(currentRFP[name]) : currentRFP[name];
  const newValue = ['roomList', 'roomTypes'].includes(name) ? JSON.stringify(field[name]) : field[name];
  if (oldValue === newValue) {
    delete rfpSession.field[name];
    rfpSession.changes = rfpSession.changes.filter(item => item !== history.label);
    rfpSession.history = rfpSession.history.filter(item => item.label !== history.label);
  } else {
    const index = rfpSession.history.findIndex(item => item.label === history.label);
    if (index >= 0) {
      rfpSession.history[index] = history;
    } else {
      rfpSession.history.push(history);
    }
    rfpSession.field = { ...rfpSession.field, ...field };
    rfpSession.changes = [...(rfpSession.changes || []), history.label];
  }
  store.commit('setRfpSession', { rfpSession, rfp, account });
};

const checkHasChanges = (rfp, field) => {
  const changes = rfp.changes || false;
  if (!changes) return false;
  return changes.includes(field);
};

const hasCounteredLast = rfp => {
  const roomRateListHistoric = rfp.roomListHistoric || [];
  const currentOwner = getCurrentOwner();
  //If there's only one entry it's crewfare
  if (roomRateListHistoric.length == 0) {
    return currentOwner === 'crewfare';
  }
  const lastEntry = [...roomRateListHistoric].reverse()[0];
  const rfpLastSignedBy =
    !lastEntry.account || ['internal', 'admin'].includes(lastEntry.account.type.toLowerCase()) ? 'crewfare' : 'hotel';
  return currentOwner === rfpLastSignedBy;
};

const getCurrentOwner = () => {
  return ['internal', 'admin'].includes(store.state.account?.type.toLowerCase()) ? 'crewfare' : 'hotel';
};

const getChangesBy = (has_changes, rfp) => {
  if (!has_changes) return;
  const currentOwner = getCurrentOwner();
  if (hasCounteredLast(rfp)) {
    return currentOwner === 'crewfare' ? 'Updated by Crewfare' : 'Updated By Hotel';
  } else {
    return currentOwner === 'crewfare' ? 'Updated by Hotel' : 'Updated by Crewfare';
  }
};

const checkIsLocked = (rfp, field) => {
  return rfp.lockedFields.includes(field);
};

const reopenRFP = async (rfp, account) => {
  const link = `${import.meta.env.VITE_URL}/rfp/${rfp.id}/view`;
  const reopenResponse = await proposalsApi.reopen(rfp.id);
  if (reopenResponse.error) {
    alert(reopenResponse.message || 'Error reopening RFP');
    return false;
  }
  const updated = reopenResponse.data.updated;
  sendNotificationAdmin({
    type: NotificationTypes.RFP_STATUS_CHANGE,
    content: `RFP ${rfp.name} status has been updated to ${updated.status} by ${account.name}`,
    to: {
      name: ViewsLocations.BID_FORM,
      params: { id: rfp.id, rfp: rfp.id },
    },
  });
  sendNotificationHotels({
    type: NotificationTypes.RFP_STATUS_CHANGE,
    ids: [rfp.hotel_id],
    content: `RFP ${rfp.name} status has been updated to ${updated.status} by ${account.name}`,
    to: {
      name: 'rfpView',
      params: { rfp: rfp.id },
    },
  });
};

const declineRFP = async (rfp, account, reasonDecline) => {
  const declined = await proposalsApi.decline(rfp.id, reasonDecline);
  if (declined.error) {
    alert(declined.message || 'Error declining RFP');
    return false;
  }
  const proposal = declined.data.updated;
  sendNotificationAdmin({
    type: NotificationTypes.RFP_STATUS_CHANGE,
    content: `RFP ${rfp.name} status has been updated to ${proposal.status} by ${account.name}`,
    to: {
      name: ViewsLocations.BID_FORM,
      params: { id: rfp.id, rfp: rfp.id },
    },
  });
  sendNotificationHotels({
    type: NotificationTypes.RFP_STATUS_CHANGE,
    ids: [rfp.hotel_id],
    content: `RFP ${rfp.name} status has been updated to ${proposal.status} by ${account.name}`,
    to: {
      name: 'rfpView',
      params: { rfp: rfp.id },
    },
  });
  return true;
};

const updateRFPNotes = async (rfp_id, field) => {
  const rfpRef = doc(firestore, 'rfp_hotel', rfp_id);
  const rfp = await getDoc(rfpRef);
  let data = rfp.data();
  const notes = data.notes || [];
  if (!notes.includes(field)) notes.push(field);
  data.notes = notes;
  data = await setStatusHistory({
    data,
    content: `Note created about ${field}`,
    status: data.status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    updated_at: new Date(),
  });
};

const markAsDoneRFPNotes = async (rfp_id, field) => {
  const rfpRef = doc(firestore, 'rfp_hotel', rfp_id);
  const rfp = await getDoc(rfpRef);
  let data = rfp.data();
  const notes = data.notes || [];
  const index = notes.findIndex(value => value === field);
  notes.splice(index, 1);
  data.notes = notes;
  data = await setStatusHistory({
    data,
    content: `Note about ${field} marked as done`,
    status: data.status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    updated_at: new Date(),
  });
};

const completeRfp = async rfp => {
  const rfpRef = doc(firestore, 'rfps', rfp.id);
  const rfpData = await getDoc(rfpRef);
  const data = {
    ...rfpData.data(),
    status: 'Completed',
    updated_at: new Date(),
  };
  await setDoc(doc(firestore, 'rfps', rfp.id), data);
  store.commit('setToast', {
    content: `<p>RFP Completed</p>`,
  });
};

const restoreRfp = async rfp => {
  const rfpRef = doc(firestore, 'rfps', rfp.id);
  const rfpData = await getDoc(rfpRef);
  const data = {
    ...rfpData.data(),
    status: 'Sent',
    updated_at: new Date(),
  };
  await setDoc(doc(firestore, 'rfps', rfp.id), data);
  store.commit('setToast', {
    content: `<p>RFP Restored</p>`,
  });
};

const requestContractEdit = async (rfp, account, reason) => {
  rfp.data().relatedEmails.forEach(email => {
    sendEmail({
      replyTo: rfp.data().replyTo,
      to: email,
      subject: `${rfp.data().hotel_name} requested a contract update for ${rfp.data().name}`,
      content: `<tr>
              <td style="color: #fff; text-align:center;font:bold 20px  Poppins,Arial,sans-serif">
                ${account.name} requested a change for the contract of ${rfp.data().name}:
              </td>
            </tr>
            <tr><td height="30px"></td></tr>
            <tr>
              <td style="color: #fff; text-align:center;font:20px  Poppins,Arial,sans-serif">
                ${reason}
              </td>
            </tr>
            <tr><td height="30px"></td></tr>
            <tr>
              <td align="center">
                <a
                  href="${import.meta.env.VITE_URL}/rfp/${rfp.id}/contract"
                  style="display: inline-block;text-decoration: none;text-transform: uppercase;padding: 16px 24px;background: #6707FD;border-radius: 999px;font: 14px/16px 'Poppins', arial;color: #FFFFFF;"
                >
                  View contract
                </a>
              </td>
            </tr>`,
    });
  });
  let data = rfp.data();
  data = await setStatusHistory({
    data,
    content: 'Request contract change',
    status: data.status,
  });
  await setDoc(doc(firestore, 'rfp_hotel', rfp.id), {
    ...data,
    updated_at: new Date(),
  });

  sendNotificationAdmin({
    type: NotificationTypes.RFP_STATUS_CHANGE,
    content: `A contract changed has been request for ${rfp.data().name} by ${account.name}`,
    to: {
      name: ViewsLocations.CONTRACT,
      params: { id: rfp.id, rfp: rfp.id },
    },
  });

  const message = await getDoc(doc(firestore, 'messages', rfp.data().message_id));
  addChat(account, message, `Contract changes requested:<br/ >${reason}`);
};

const saveRfpSession = async ({ rfp, account, rfpSession }) => {
  await setDoc(doc(firestore, 'rfp_tmp', `${rfp.id}-${account.id}`), {
    rfpSession,
    rfp: rfp.id,
    created_at: new Date(),
    account: account.id,
  });
};

const addToRfp = async ({ rfp, hotels }) => {
  if (!confirm('Assign selected hotels to this RFP?')) return;
  let selectedHotels = hotels.filter(hotel => hotel.selected);
  if (selectedHotels.length === 0) selectedHotels = hotels;
  const rfpData = await rfpsApi.get(rfp.id).then(response => response.data || {});
  const selected = [];
  selectedHotels.forEach(hotel => selected.push(hotel.id));
  const shared_with = rfpData.shared_with;
  selected.forEach(async id => {
    const qContacts = query(collection(firestore, 'contacts'), where('hotel_id', '==', id));
    const contacts = await getDocs(qContacts);
    const hotelsContacts = contacts.docs.map(contact => contact.id);
    shared_with.push(...hotelsContacts);
  });
  const rfpHotels = rfpData.hotels || [];
  rfpHotels.push(...selected);
  await setDoc(doc(firestore, 'rfps', rfpData.id), {
    ...rfpData,
    hotels: rfpHotels,
    shared_with,
    updated_at: new Date(),
  });
  const proposals = await rfpsApi.listProposals(rfpData.id).then(response => response.data || []);
  const hotelMappedProposals = convertArrayToKeyMappedObjectArrays(proposals, 'hotel_id');
  let duplicates = 0;
  for (const hotel of selectedHotels) {
    if (!hotelMappedProposals[hotel.id]?.length) {
      const status_history = [
        {
          created_at: new Date(),
          content: 'RFP created',
          status: 'New',
          account: store.state.account,
        },
      ];
      await addDoc(collection(firestore, 'rfp_hotel'), {
        ...rfpData,
        hotel_id: hotel.id,
        status_history,
        hotel_name: hotel.name || hotel.data().name,
        distance: hotel.distance || '',
        hotel_address: hotel.addrFull || hotel.addr || '',
        value: hotel.value || '',
        rfp: rfpData.id,
        created_at: new Date(),
        updated_at: new Date(),
        status: 'New',
      });
      store.commit('setToast', {
        content: `<div class='flex flex-col gap-2'>
            <p>${hotel.name || hotel.data().name} added to ${rfpData.name}</p>
          </div>`,
      });
    } else {
      console.log('Duplicate proposal', hotel, hotelMappedProposals[hotel.id]);
      duplicates++;
    }
  }
  if (duplicates > 0) {
    alert(`${duplicates} already hotel were found`);
  }
  store.commit('setToast', {
    content: `<div class='flex flex-col gap-2'>
            <p>${selected.length} added to ${rfpData.name}</p>
            <p>You can now send this RFP!</p>
          </div>`,
  });
};

const isRFPExpired = rfp => {
  const now = new Date();
  const expiringDate = getDateObjFromFirebaseDate(rfp.bidExpirationDate || rfp.startDate);
  return now > expiringDate;
};

const listEmailHistoryForRFP = async rfpId => {
  const token = await getIdToken();
  const url = `${import.meta.env.VITE_V2_API_URL}/rfp/${rfpId}/email-history`;
  const response = await fetch(`${import.meta.env.VITE_V2_API_URL}/rfp/${rfpId}/email-history`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const history = await response.json();
  console.log('Response', history);
  return history;
};
const validateEmail = email => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const emailHistoriesForRFPIds = async proposalIds => {
  const response = await emailsApi.history(proposalIds);
  const history = (await response.data) || {};
  return history;
};

const getRFP = async rfpId => {
  return rfpsApi.get(rfpId).then(response => response.data);
};

const getLocationDenotor = (address = '') => {
  if (!address) return '';
  let searchArray = address.trim().split(',');
  //Filter item which is either blank or contain any numericak value
  searchArray = searchArray.filter(item => searchArray.length <= 2 || !item.match(/\d+/g));
  if (searchArray.length < 3) {
    return searchArray.join(',<br>');
  }
  const part1 = searchArray[searchArray.length - 3];
  const part2 = searchArray[searchArray.length - 2];
  return `${part1}, <br>${part2}`;
};

export {
  addToRfp,
  saveRfpSession,
  completeRfp,
  restoreRfp,
  sendRFP,
  createSimpleRFP,
  RFPStatusPill,
  sendSingleRFP,
  forwardRFP,
  sendReminderRFP,
  isRFPExpired,
  updateRFPNotes,
  markAsDoneRFPNotes,
  statusList,
  saveField,
  checkHasChanges,
  getChangesBy,
  contractPhase,
  checkIsLocked,
  reopenRFP,
  declineRFP,
  setStatusHistory,
  requestContractEdit,
  groupStatus,
  listEmailHistoryForRFP,
  emailHistoriesForRFPIds,
  getRFP,
  getLocationDenotor,
};
