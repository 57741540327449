import { BaseApi, getToken } from './apiFactory';

class OAuthAppsApiClass extends BaseApi {
  async authorize(clientId: string) {
    const token = await getToken();
    return this.axios({
      method: 'post',
      url: `${this.apiUrl}/authorize`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { clientId },
    });
  }
}

export const OAuthAppsApi = new OAuthAppsApiClass({ name: 'oauth-apps' });
